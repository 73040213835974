import * as types from "./actionTypes";
import * as userApi from "../../api/userApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function enrollInCourseSuccess(userCourse) {
  return { type: types.USER_ENROLL_IN_COURSE_SUCCESS, userCourse };
}
export function loadUserStudentCoursesSuccess(response) {
  return { type: types.USER_LOAD_STUDENT_COURSES_SUCCESS, response };
}
export function saveStudentAnswerSuccess(response) {
  return { type: types.USER_SAVE_STUDENT_ANSWER_SUCCESS, response };
}
export function incrementActivePartCourseQsCompleted(num) {
  return { type: types.INCREMENT_ACTIVE_PART_COURSE_QS_COMPLETED, num };
}
export function loadUserProfileSuccess(response) {
  return { type: types.USER_LOAD_PROFILE_SUCCESS, response };
}
export function loadUserProfileFailure(error) {
  return { type: types.USER_LOAD_PROFILE_FAILURE, error };
}

export function enrollInCourse(courseId, userId, token) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .enrollInCoursePost(courseId, userId, token)
      .then((userCourse) => {
        dispatch(enrollInCourseSuccess(userCourse));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function saveStudentAnswer(answer) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .saveStudentAnswer(answer)
      .then((response) => {
        dispatch(saveStudentAnswerSuccess(response));
        dispatch(
          incrementActivePartCourseQsCompleted(
            response.numberOfQuestionsCompletedInCourse
          )
        );
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserStudentCourses(userId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserStudentCourses(userId)
      .then((response) => {
        dispatch(loadUserStudentCoursesSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadUserProfile(token, email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUserProfile(token, email)
      .then((response) => {
        dispatch(loadUserProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        dispatch(loadUserProfileFailure(error));
      });
  };
}
export function loadResendUserVerificationEmail(token, externalId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi.resendVerificationEmail(token, externalId).catch((error) => {
      dispatch(apiCallError(error));
      throw error;
    });
  };
}

export function setUserToken(token) {
  return function (dispatch) {
    dispatch({ type: types.USER_LOAD_TOKEN_SUCCESS, token });
  };
}

export function userLogout() {
  return function (dispatch) {
    dispatch({ type: types.USER_LOGOUT });
  };
}

export const saveUserProfile = (userForm) => {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { dbUser, token } = userProfile;
    const nameUpdated = () => {
      if (dbUser.userId != null) {
        return (
          dbUser.firstName.trim() + " " + dbUser.lastName.trim() !=
          userForm.firstName.trim() + " " + userForm.lastName.trim()
        );
      } else {
        return true;
      }
    };
    const groupUpdated = () => {
      if (dbUser.userId != null) {
        return (
          dbUser.preferredLanguage != userForm.preferredLanguage ||
          parseInt(dbUser.gender) != parseInt(userForm.gender)
        );
      } else {
        return true;
      }
    };
    const data = {
      Email:
        dbUser.email != userForm.email.trim() ? userForm.email.trim() : null,
      ExternalId: userForm.externalId,
      FirstName: nameUpdated() ? userForm.firstName.trim() : null,
      LastName: nameUpdated() ? userForm.lastName.trim() : null,
      IsActive: true,
      IsGrader: dbUser.IsGrader || false,
      HasCompletedInitialCourse: dbUser.HasCompletedInitialCourse || false,
      RegionId:
        parseInt(dbUser.regionId) != parseInt(userForm.regionId)
          ? userForm.regionId
          : null,
      DateOfBirth:
        parseInt(dbUser.dateOfBirth) != parseInt(userForm.dateOfBirth)
          ? userForm.dateOfBirth
          : null,
      PreferredLanguage: groupUpdated() ? userForm.preferredLanguage : null,
      Gender: groupUpdated() ? userForm.gender : null,
    };
    dispatch(beginApiCall());
    return userApi
      .saveUserProfile(token, data)
      .then((response) => {
        dispatch(loadUserProfileSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};
