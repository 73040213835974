import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function appPreferencesReducer(
  state = initialState.appPreferences,
  action
) {
  switch (action.type) {
    case types.SET_PREF_REVIEW_TEXT:
      return { ...state, showTextWhenReviewLesson: action.preference };
    default:
      return state;
  }
}
