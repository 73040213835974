export async function handleResponse(response) {
  if (response.ok) {
    const json = await response.json();
    return json;
  }

  const err = await response.json();

  if (err.message) {
    throw new Error(err.message);
  }
  throw new Error(response.statusText);
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  throw error.message;
}
