import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function lessonReducer(state = initialState.activePart, action) {
  switch (action.type) {
    case types.LOAD_ACTIVE_PART_SUCCESS:
      return action.part;
    case types.INCREMENT_ACTIVE_PART_COURSE_QS_COMPLETED:
      return {
        ...state,
        course: {
          ...state.course,
          countOfQuestionsCompletedInCourse: action.num,
        },
      };
    case types.CLEAR_ACTIVE_PART:
      return {};
    default:
      return state;
  }
}
