import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import onestudentsmall from "../../resources/images/1student-small.jpg";
import { Helmet } from "react-helmet";
import HomeActionCard from "./HomeActionCard";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("welcome-to") + " " + t("mt-zion-bible-institute")}</title>
        <meta name="description" content="" />
      </Helmet>
      <Row
        style={{
          backgroundImage: `url(${onestudentsmall})`,
          backgroundPosition: "center",
          height: "65vh",
          alignContent: "flex-end",
        }}
        className="mb-3 d-none d-md-flex"
      >
        <Col style={{ backgroundColor: "#008751e8" }}>
          <h1 className="display-4 text-center">
            {t("welcome-to")} {t("mt-zion-bible-institute")}!
          </h1>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md="6">
          <HomeActionCard />
        </Col>
        <Col md="6">
          <Card>
            <CardBody>
              <div dangerouslySetInnerHTML={{ __html: t("home-blurb") }} />
            </CardBody>
            <CardFooter>
              <Link to="about" className="btn btn-primary btn-lg">
                {t("learn-more")}
              </Link>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default HomePage;
