import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardText,
  CardImg,
  CardImgOverlay,
  CardGroup,
  CardFooter,
  CardBody,
  Progress,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { loadMostRecentCourse } from "../../redux/actions/courseActions";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ContinueActiveCourseCard = ({ loadMostRecentCourse, recentCourse }) => {
  const { t } = useTranslation();

  const [triedToLoadRecentCourse, setTriedToLoadRecentCourse] = useState(false);

  useEffect(() => {
    if (recentCourse && !recentCourse.title && !triedToLoadRecentCourse) {
      loadMostRecentCourse();
      setTriedToLoadRecentCourse(true);
    }
  }, [recentCourse]);

  if (!recentCourse.title) {
    return <Spinner />;
  }
  return (
    <>
      <CardGroup>
        <Card inverse>
          <CardImg
            top
            width="50%"
            src={
              process.env.REACT_APP_API_URL +
              "/images/covers/courses/" +
              recentCourse.code +
              ".jpg"
            }
            alt={t("continue") + " " + t("course")}
          />
          <CardImgOverlay>
            <h3>{recentCourse.title}</h3>
            <CardText>
              {recentCourse.author.name} {recentCourse.author.date}
            </CardText>
            <CardText>{recentCourse.shortDescription}</CardText>
          </CardImgOverlay>
        </Card>
        <Card>
          <CardHeader>
            <h2>{t("continue-where-left-off")}</h2>
          </CardHeader>
          <CardBody>
            {recentCourse.countOfQuestionsCompletedInCourse > 0 &&
              recentCourse.countOfQuestionsCompletedInCourse <
                recentCourse.countOfQuestionsInCourse && (
                <Progress
                  color="primary"
                  value={recentCourse.countOfQuestionsCompletedInCourse}
                  max={recentCourse.countOfQuestionsInCourse}
                  className="mb-2 mt-2 bg-secondary"
                >
                  {t("you-have-completed")}{" "}
                  {recentCourse.countOfQuestionsCompletedInCourse} {t("of")}{" "}
                  {recentCourse.countOfQuestionsInCourse} {t("questions")}{" "}
                  {t("in")} {recentCourse.title}
                </Progress>
              )}
            {recentCourse.countOfQuestionsCompletedInCourse >=
              recentCourse.countOfQuestionsInCourse && (
              <>
                {t("completed-heading") +
                  " " +
                  recentCourse.title +
                  " (" +
                  recentCourse.code +
                  ") "}
                <Progress
                  color="primary"
                  value={recentCourse.countOfQuestionsCompletedInCourse}
                  max={recentCourse.countOfQuestionsInCourse}
                  className="mb-2 mt-2 bg-secondary"
                >
                  {recentCourse.countOfQuestionsCompletedInCourse} {t("of")}{" "}
                  {recentCourse.countOfQuestionsInCourse} {t("questions")}{" "}
                  {t("in")} {recentCourse.title}
                </Progress>
                <div
                  dangerouslySetInnerHTML={{ __html: t("completed-text") }}
                />
              </>
            )}
          </CardBody>
          <CardFooter>
            {!recentCourse.dateCompleted && recentCourse.nextPartId && (
              <Link
                className="btn btn-primary btn-sm small"
                to={
                  "/course/" +
                  recentCourse.code +
                  "/part/" +
                  recentCourse.nextPartId
                }
              >
                {t("continue") +
                  " " +
                  t("course") +
                  " " +
                  recentCourse.code.toUpperCase()}
              </Link>
            )}
            {(recentCourse.dateCompleted || !recentCourse.nextPartId) && (
              <Link to="courses" className="btn btn-primary btn-sm small">
                {t("go-to-courses")}
              </Link>
            )}
          </CardFooter>
        </Card>
      </CardGroup>
    </>
  );
};

ContinueActiveCourseCard.propTypes = {
  recentCourse: PropTypes.object.isRequired,
  loadMostRecentCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  recentCourse: state.mostRecentCourse,
});

const mapDispatchToProps = {
  loadMostRecentCourse,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContinueActiveCourseCard);
