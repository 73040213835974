import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Input, Button, Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MentorCourseCommentForm = ({
  course,
  onSaveResponseClick,
  groupId,
  questions,
  setRedirect,
}) => {
  const { t } = useTranslation(["translation", "mentor"]);
  const [saving, setSaving] = useState(false);
  const [validComment, setValidComment] = useState(true);
  const [errors, setErrors] = useState({});

  const [commentObject, setCommentObject] = useState({
    groupId,
    usersCourseId: course.usersCourseId,
    gradeComment: course.graderCourseComment,
  });

  function formIsValid() {
    const { gradeComment } = commentObject;
    const _errors = {};
    if (!gradeComment) {
      setValidComment(false);
      _errors.gradeComment = "mentor:mentor-must-comment";
      setErrors(_errors);
    }
    let gradeMarkCount = questions.filter((q) => q.gradeMark != null).length;
    if (gradeMarkCount < questions.length) {
      alert(gradeMarkCount + " " + t("done-of") + " " + questions.length);
      setValidComment(false);
      _errors.gradeComment = "mentor:mentor-must-mark-all-questions";
      setErrors(_errors);
    }

    return Object.keys(_errors).length === 0;
  }

  function handleSave(event) {
    event.preventDefault();
    if (!formIsValid()) return;
    setSaving(true);
    onSaveResponseClick(commentObject)
      .then(() => {
        setSaving(false);
        toast.success(
          <>
            <h2>{t("thank-you")}</h2>
            <p>{t("mentor:mentor-completed-grading")}.</p>
          </>,
          { autoClose: 10000 }
        );
        setRedirect("/mentor");
      })
      .catch((error) => {
        setErrors({ onSave: error.message });
        setSaving(false);
      });
  }
  function handleChange(event) {
    const { name, value } = event.target;
    setCommentObject((prevCommentObject) => ({
      ...prevCommentObject,
      [name]: value,
    }));
  }
  function showCommentSaveButton() {
    if (
      course.graderCourseComment &&
      commentObject.gradeComment == course.graderCourseComment
    ) {
      return (
        <Button color="secondary" size="lg" className="mt-2">
          {t("response-saved") +
            " " +
            t("and") +
            " " +
            t("mentor:mentor-mark-course-complete")}
        </Button>
      );
    } else if (course.graderCourseComment) {
      return (
        <Button color="primary" size="lg" className="mt-2" onClick={handleSave}>
          {t("response-update") +
            " " +
            t("and") +
            " " +
            t("mentor:mentor-mark-course-complete")}
        </Button>
      );
    } else {
      return (
        <Button color="primary" size="lg" className="mt-2" onClick={handleSave}>
          {t("response-save") +
            " " +
            t("and") +
            " " +
            t("mentor:mentor-mark-course-complete")}
        </Button>
      );
    }
  }
  return (
    <>
      <h2 className="mt-5">
        {t("mentor:mentor-comment-on-course")} {course.studentName}
      </h2>
      <Input
        type="textarea"
        id="gradeComment"
        name="gradeComment"
        label="Grade comment"
        value={commentObject.gradeComment + ""}
        onChange={handleChange}
        error={errors.gradeComment}
        valid={validComment}
        placeholder={t("mentor:mentor-must-comment")}
      />
      {errors && errors.gradeComment && (
        <Alert color="danger">{t(errors.gradeComment)}</Alert>
      )}
      {showCommentSaveButton()}{" "}
      {saving && <FontAwesomeIcon icon={faSpinner} spin />}
      <Alert color="light" className="mb-5">
        {!course.dateGraded && t("mentor:mentor-save-for-later")}
      </Alert>
    </>
  );
};

MentorCourseCommentForm.propTypes = {
  questions: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
  onSaveResponseClick: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  setRedirect: PropTypes.func.isRequired,
};

export default MentorCourseCommentForm;
