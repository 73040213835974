import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  loadCourses,
  loadCourseTopics,
  loadCourseLevels,
} from "../../redux/actions/courseActions";
import { enrollInCourse } from "../../redux/actions/userActions";
import PropTypes from "prop-types";
import CourseList from "./CourseList";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const CoursesPage = ({
  enrollInCourse,
  loadCourses,
  loadCourseTopics,
  loadCourseLevels,
  courses,
  courseLevels,
  courseTopics,
  loading,
  dbUser,
  userToken,
  history,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language.substr(0, 2);
  useEffect(() => {
    loadCourses().catch((error) => {
      toast.error(t("loading-failed") + " \n\n" + error);
    });
  }, []);
  useEffect(() => {
    if (courseTopics.length == 0) {
      loadCourseTopics(currentLang).catch((error) => {
        toast.error(t("loading-failed") + " \n\n" + error);
      });
    }
  }, [courseTopics]);
  useEffect(() => {
    if (courseLevels.length == 0) {
      loadCourseLevels().catch((error) => {
        toast.error(t("loading-failed") + " \n\n" + error);
      });
    }
  }, [courseLevels]);
  const handleSignupCourse = async (course) => {
    try {
      await enrollInCourse(course.code, dbUser.userId, userToken);
      toast.success(t("sign-up-succeeded"));
      history.push("/course/" + course.code);
    } catch (error) {
      toast.error(t("sign-up-failed") + ". " + error.message, {
        autoClose: false,
      });
    }
  };
  const handleResumeCourse = (course) => {
    history.push("/course/" + course.code + "/part/" + course.nextPartId);
  };

  return (
    <div
      className="text-relavent mb-4 container-fluid shadow p-1 p-md-5 bg-light rounded"
      style={{ maxWidth: "850px" }}
    >
      <Helmet>
        <title>{t("courses")} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="display-4">{t("courses")}</h1>

      <div style={{ height: "100%" }}>
        {loading ? (
          <Spinner text={t("loading") + " " + t("courses")} />
        ) : (
          <CourseList
            onSignupClick={handleSignupCourse}
            onResumeClick={handleResumeCourse}
            courses={courses}
            userIsLoggedIn={!dbUser.userId ? false : true}
            userHasCompletedInitialCourse={
              !dbUser.userId ? false : dbUser.hasCompletedInitialCourse
            }
            topics={courseTopics}
            levels={courseLevels}
            token={userToken}
          />
        )}
      </div>
    </div>
  );
};

CoursesPage.propTypes = {
  courses: PropTypes.array.isRequired,
  loadCourses: PropTypes.func.isRequired,
  enrollInCourse: PropTypes.func.isRequired,
  dbUser: PropTypes.object,
  history: PropTypes.object.isRequired,
  userToken: PropTypes.string,
  userStudentCourses: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  loadCourseTopics: PropTypes.func.isRequired,
  loadCourseLevels: PropTypes.func.isRequired,
  courseTopics: PropTypes.array.isRequired,
  courseLevels: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    courses: state.courses,
    courseTopics: state.courseTopics,
    courseLevels: state.courseLevels,
    loading: state.courses.length < 1,
    dbUser: state.userProfile.dbUser,
    userToken: state.userProfile.token,
    userStudentCourses: state.userStudentCourses,
  };
}

const mapDispatchToProps = {
  enrollInCourse,
  loadCourses,
  loadCourseTopics,
  loadCourseLevels,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesPage);
