import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardText,
  CardImg,
  CardImgOverlay,
  CardGroup,
  CardFooter,
  CardBody,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { loadInitialCourses } from "../../redux/actions/courseActions";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import PropTypes from "prop-types";

const InitialCourseCard = ({ loadInitialCourses, initialCourse }) => {
  const { i18n, t } = useTranslation();
  const activeLang = i18n.language.substr(0, 2);
  const [triedToLoadInitialCourse, setTriedToLoadInitialCourse] =
    useState(false);

  useEffect(() => {
    if (initialCourse && !initialCourse.code && !triedToLoadInitialCourse) {
      loadInitialCourses(activeLang);
      setTriedToLoadInitialCourse(true);
    }
  }, [initialCourse]);

  if (!initialCourse.code) {
    return <Spinner />;
  }
  return (
    <>
      {initialCourse && (
        <CardGroup>
          <Card inverse>
            <CardImg
              top
              width="50%"
              src={
                process.env.REACT_APP_API_URL +
                "/images/covers/courses/" +
                initialCourse.code +
                ".jpg"
              }
              alt={t("get-started")}
            />
            <CardImgOverlay>
              <h3>{initialCourse.title}</h3>
              <CardText>
                {initialCourse.author.name} {initialCourse.author.date}
              </CardText>
              <CardText>{initialCourse.shortDescription}</CardText>
            </CardImgOverlay>
          </Card>
          <Card>
            <CardHeader>
              <h2>{t("get-started")}</h2>
            </CardHeader>
            <CardBody>
              <CardText>
                {t("get-started-text", {
                  title: initialCourse.title,
                  author: initialCourse.author.name,
                })}
              </CardText>
            </CardBody>
            <CardFooter>
              <Link to="courses" className="btn btn-primary btn-lg">
                {t("go-to-courses")}
              </Link>
            </CardFooter>
          </Card>
        </CardGroup>
      )}
    </>
  );
};

InitialCourseCard.propTypes = {
  initialCourse: PropTypes.object.isRequired,
  loadInitialCourses: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  initialCourse: state.initialCourse,
});

const mapDispatchToProps = {
  loadInitialCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialCourseCard);
