import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import MentorCourseCard from "./MentorCourseCard";
import DropDown from "../common/DropDown";
import { connect } from "react-redux";
import {
  loadAssignCourseToMentor,
  loadMentorCourses,
} from "../../redux/actions/mentorActions";
import { Redirect } from "react-router-dom";
import { loadCourses } from "../../redux/actions/courseActions";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const MentorDashboard = ({
  dbUser,
  loadMentorCourses,
  mentorCourseLists,
  loadAssignCourseToMentor,
  loadCourses,
  courses,
}) => {
  const { t } = useTranslation(["translation", "mentor"]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    if (dbUser.userGroups && dbUser.userGroups.length > 0) {
      let filteredGroup = dbUser.userGroups.filter(
        (g) => g.isDefault == true
      )[0];
      if (!filteredGroup) {
        filteredGroup = dbUser.userGroups[0];
      }
      setSelectedGroup({ name: filteredGroup.name, id: filteredGroup.id });
    }
  }, [dbUser.userGroups]);

  useEffect(() => {
    if (courses.length == 0) {
      loadCourses().catch((error) => {
        toast.error(t("loading-failed") + " \n\n" + error);
      });
    }
  }, [courses]);

  useEffect(() => {
    if (selectedGroup && selectedGroup.id > 0) {
      loadMentorCourses(selectedGroup.id, "active");
      loadMentorCourses(selectedGroup.id, "history");
    }
  }, [dbUser.userGroups, selectedGroup]);

  useEffect(() => {
    if (selectedGroup && selectedGroup.id > 0) {
      loadMentorCourses(selectedGroup.id, "potential");
    }
  }, [mentorCourseLists.mentorActiveCourses]);

  useEffect(() => {
    if (dbUser.userId && !dbUser.isGrader) {
      setRedirect("/courses");
    }
  }, [dbUser]);

  const onActiveClick = (groupId, userCourseId) => {
    setRedirect(`/mentor/${groupId}/grade/${userCourseId}`);
  };

  const onGroupClick = ({ target }) => {
    setSelectedGroup({ name: target.innerText, id: target.value });
  };

  const onPotentialClick = (groupId, usersCourseId) => {
    if (mentorCourseLists.mentorActiveCourses.length > 2) {
      toast.warning(
        <>
          <h3>{t("mentor:mentor-only-three-active-heading")}</h3>
          <p>{t("mentor:mentor-only-three-active-text")}</p>
        </>
      );
    } else {
      loadAssignCourseToMentor(groupId, usersCourseId);
    }
  };

  const onFilterPotentialCoursesClick = (courseId) => {
    loadMentorCourses(selectedGroup.id, "potential", courseId);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      <Helmet>
        <title>{t("mentors-dashboard")} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="display-4 m-3">{t("mentors-dashboard")}</h1>

      <Container fluid="lg" className="mt-5">
        <Row>
          <Col md="12">
            {selectedGroup && (
              <h2 className="text-center">{selectedGroup.name}</h2>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <p className="text-center">{t("mentor:mentor-select-group")}:</p>
            <DropDown
              errorMessage={""}
              items={dbUser.userGroups}
              label={
                selectedGroup
                  ? selectedGroup.name
                  : `${t("select")} ${t("group")}`
              }
              text="name"
              value="id"
              enabled={true}
              onClick={onGroupClick}
              isValid={true}
            />
          </Col>
          <Col md="4"></Col>
        </Row>
        <Row>
          <MentorCourseCard
            titleI18nKey="mentor:mentor-courses-now-grading-heading"
            textI18nKey="mentor:mentor-courses-now-grading-text"
            bgColor="#96f7d1"
            badge={true}
            userCourses={mentorCourseLists.mentorActiveCourses}
            onCourseClick={onActiveClick}
          />
          <MentorCourseCard
            titleI18nKey="mentor:mentor-courses-potential-heading"
            textI18nKey="mentor:mentor-courses-potential-text"
            bgColor="#04c87acc"
            userCourses={mentorCourseLists.mentorPotentialCourses}
            onCourseClick={onPotentialClick}
            coursesForFilter={courses}
            onFilterPotentialCoursesClick={onFilterPotentialCoursesClick}
          />
          <MentorCourseCard
            titleI18nKey="mentor:mentor-courses-graded-heading"
            textI18nKey="mentor:mentor-courses-graded-text"
            bgColor="#008751"
            userCourses={mentorCourseLists.mentorHistoryCourses}
            onCourseClick={() => null}
          />
        </Row>
      </Container>
    </>
  );
};

MentorDashboard.propTypes = {
  dbUser: PropTypes.object.isRequired,
  loadMentorCourses: PropTypes.func.isRequired,
  mentorCourseLists: PropTypes.object.isRequired,
  loadAssignCourseToMentor: PropTypes.func.isRequired,
  loadCourses: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    loadMentorCourses: (groupId, status, courseId) =>
      dispatch(loadMentorCourses(groupId, status, courseId)),
    loadAssignCourseToMentor: (groupId, usersCourseId) =>
      dispatch(loadAssignCourseToMentor(groupId, usersCourseId)),
    loadCourses: () => dispatch(loadCourses()),
  };
}

const mapStateToProps = (state) => ({
  dbUser: state.userProfile.dbUser,
  mentorCourseLists: state.mentorCourses,
  courses: state.courses,
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorDashboard);
