import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  loadMessages,
  updateMessage,
} from "../../redux/actions/messageActions";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import { Table, Button } from "reactstrap";
import {
  capitalizeFirstLetter as cf,
  formatDate,
  formatTime,
} from "../../common/util";
import { Helmet } from "react-helmet";

const Messages = ({ messages, loadMessages, updateMessage }) => {
  const [triedToLoad, setTriedToLoad] = useState(false);

  useEffect(() => {
    if (!triedToLoad && messages.length < 1) {
      try {
        loadMessages();
        setTriedToLoad(true);
      } catch (error) {
        toast.error(t("loading-failed") + " \n\n" + error);
      }
    }
  }, [triedToLoad, messages]);

  const { t, language } = useTranslation();
  const dateOptions = {
    weekday: "short",
    year: "2-digit",
    month: "short",
    day: "numeric",
  };
  const timeOptions = {
    timeStyle: "short",
  };
  return (
    <div
      className="text-relavent mb-4 container-fluid shadow p-1 p-md-5 bg-light rounded"
      style={{ maxWidth: "850px" }}
    >
      <Helmet>
        <title>{t("messages-header")} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="display-4">{t("messages-header")}</h1>
      <Table striped>
        <thead>
          <tr>
            <th>{t("date")}</th>
            <th>{t("mentor")}</th>
            <th>{cf(t("course"))}</th>
            <th>{t("message")}</th>
            <th>{t("mark-as-read")}</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => {
            return (
              <tr key={message.id}>
                <th
                  scope="row"
                  className={
                    message.dateRead === null
                      ? "border-left border-success"
                      : ""
                  }
                >
                  {formatDate(message.dateAdded + "Z", language, dateOptions)}
                  <br />
                  <small>
                    <em>
                      {formatTime(
                        message.dateAdded + "Z",
                        language,
                        timeOptions
                      )}
                    </em>
                  </small>
                </th>
                <td>{message.graderName}</td>
                <td>{message.courseTitle}</td>
                <td>{t(message.content, { mentor: message.graderName })}</td>
                <td>
                  {message.dateRead === null && (
                    <Button
                      onClick={() => {
                        updateMessage(message);
                      }}
                    >
                      {t("got-it")}
                    </Button>
                  )}
                  {message.dateRead && (
                    <>
                      {formatDate(message.dateRead, language, dateOptions)}
                      <br />
                      <small>
                        <em>
                          {formatTime(message.dateRead, language, timeOptions)}
                        </em>
                      </small>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

Messages.propTypes = {
  messages: PropTypes.array.isRequired,
  loadMessages: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    messages: state.messages,
  };
}

const mapDispatchToProps = {
  loadMessages,
  updateMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
