import React, { useEffect, useState } from "react";
import { UncontrolledAlert } from "reactstrap";
import { useTranslation } from "react-i18next";
import { loadSiteNotice } from "../../redux/actions/siteNoticeActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const SiteNotice = ({ loadSiteNotice, siteNoticeObject }) => {
  const { i18n } = useTranslation();
  const [triedToLoadNotice, setTriedToLoadNotice] = useState(false);

  useEffect(() => {
    if (!triedToLoadNotice) {
      setTriedToLoadNotice(true);
      loadSiteNotice();
    }
  }, [triedToLoadNotice, siteNoticeObject]);

  const fieldName = "text_" + i18n.language.substr(0, 2).toUpperCase();

  return (
    <>
      {siteNoticeObject[fieldName] && (
        <UncontrolledAlert color="warning" className="mb-0">
          {siteNoticeObject[fieldName]}
        </UncontrolledAlert>
      )}
      {!siteNoticeObject[fieldName] && null}
    </>
  );
};

SiteNotice.propTypes = {
  loadSiteNotice: PropTypes.func.isRequired,
  siteNoticeObject: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    siteNoticeObject: state.siteNotice,
  };
}

const mapDispatchToProps = {
  loadSiteNotice,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteNotice);
