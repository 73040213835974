import React, { useState } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavLink,
  NavItem,
  NavbarToggler,
  NavbarBrand,
  Collapse,
  Badge,
} from "reactstrap";
import AuthNav from "../authentication/auth-nav";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LanguageSelector from "./LanguageSelector";
import SiteNotice from "./SiteNotice";

const Header = ({ isGrader, unreadMessages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const activeStyle = { color: "#F15B2A", fontWeight: "bold" };
  const { t, i18n } = useTranslation();

  document.documentElement.lang = i18n.language.substr(0, 2);

  return (
    <>
      <Navbar className="bg-dark-green" light expand="md">
        <NavbarBrand href="/" className="text-light">
          {t("mt-zion-bible-institute")}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} aria-label={t("show-or-hide")} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto">
            {isGrader && (
              <NavItem>
                <NavLink
                  className="nav-link text-light"
                  to="/mentor"
                  activeStyle={activeStyle}
                  tag={RRNavLink}
                >
                  {t("mentors-dashboard")}
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className="nav-link text-light"
                to="/courses"
                activeStyle={activeStyle}
                tag={RRNavLink}
              >
                {t("courses")}
              </NavLink>
            </NavItem>
            {unreadMessages > 0 && (
              <NavItem>
                <NavLink
                  className="nav-link text-light"
                  to="/messages"
                  activeStyle={activeStyle}
                  tag={RRNavLink}
                >
                  {t("messages")}{" "}
                  <Badge color="primary" pill>
                    {unreadMessages}
                  </Badge>
                </NavLink>
              </NavItem>
            )}

            <AuthNav />

            <NavItem>
              <NavLink
                className="nav-link text-light"
                to="/about"
                activeStyle={activeStyle}
                tag={RRNavLink}
              >
                {t("about")}
              </NavLink>
            </NavItem>
            <LanguageSelector inNav={true} />
          </Nav>{" "}
        </Collapse>
      </Navbar>
      <SiteNotice />
    </>
  );
};

Header.propTypes = {
  isGrader: PropTypes.bool,
  messages: PropTypes.array,
  unreadMessages: PropTypes.number,
};

export default Header;
