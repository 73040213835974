import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loadUserProfile,
  setUserToken,
  userLogout,
} from "../redux/actions/userActions";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "../auth/ProtectedRoute";
import ProtectedRouteForForms from "../auth/ProtectedRouteForForms";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import AboutMentors from "./about-mentors/AboutMentors";
import UserProfile from "./user/UserProfile";
import Header from "./common/Header";
import PageNotFound from "./PageNotFound";
import CoursesPage from "./courses/CoursesPage";
import CompletedCourse from "./courses/CompletedCourse";
import { ToastContainer } from "react-toastify";
import Spinner from "./common/Spinner";
import PropTypes from "prop-types";
import "react-toastify/dist/ReactToastify.css";
import LessonDetail from "./courses/LessonDetail";
import Footer from "./common/Footer";
import MentorDashboard from "./mentor/MentorDashboard";
import MentorGradeCourse from "./mentor/MentorGradeCourse";
import CourseDetail from "./courses/CourseDetail";
import ScrollToTop from "./common/ScrollToTop";
import TermsOfUse from "./terms/TermsOfUse";
import Messages from "./messages/Messages";

function App({
  loadUserProfile,
  userProfile,
  setUserToken,
  userToken,
  userLogout,
}) {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();

  const [appLoading, setAppLoading] = useState(true);
  const [mustCompleteUserProfile, setMustCompleteUserProfile] = useState(false);

  const loadToken = async () => {
    try {
      const t = await getAccessTokenSilently();
      setUserToken(t);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isLoading && !isAuthenticated) {
      setAppLoading(false);
      userLogout();
      return;
    }
    if (!isLoading && isAuthenticated && !userProfile.triedToLoad) {
      if (typeof user !== "undefined" && user.sub.length > 0) {
        if (!userToken) {
          loadToken();
        }
        if (userToken) {
          loadUserProfile(userToken, user.email);
        }
      }
      return;
    }
    if (
      userProfile.triedToLoad &&
      !userProfile.dbUser.userId &&
      location.pathname != "/user-profile"
    ) {
      setMustCompleteUserProfile(true);
    }
    if (location.pathname == "/user-profile") {
      setMustCompleteUserProfile(false);
    }
    if (userProfile.triedToLoad && !mustCompleteUserProfile && appLoading) {
      setAppLoading(false);
    }
  }, [
    isAuthenticated,
    isLoading,
    user,
    userToken,
    userProfile.triedToLoad,
    location.pathname,
    mustCompleteUserProfile,
    appLoading,
  ]);

  if (appLoading) {
    return <Spinner text="Authenticating" />;
  }
  if (mustCompleteUserProfile) {
    return <Redirect to="/user-profile" />;
  }

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <div>
          <Header
            isGrader={userProfile.dbUser.isGrader == true}
            unreadMessages={userProfile.dbUser.unreadMessages}
          />
          <main className="container-fluid" role="main">
            <ScrollToTop />
            <Switch>
              <ProtectedRouteForForms
                path="/user-profile"
                component={UserProfile}
              />

              <Route exact path="/" component={HomePage} />
              <Route path="/about" component={AboutPage} />

              <Route path="/courses" component={CoursesPage} />
              <ProtectedRoute
                path="/course/:courseCode"
                component={CourseDetail}
                exact
              />
              <ProtectedRouteForForms
                path="/course/:courseCode/part/:partId"
                component={LessonDetail}
              />
              <ProtectedRoute
                path="/completed/:courseCode"
                component={CompletedCourse}
              />
              <ProtectedRoute
                path="/mentor/:groupId/grade/:userCourseId/"
                component={MentorGradeCourse}
              />
              <ProtectedRouteForForms
                path="/mentor"
                component={MentorDashboard}
              />
              <ProtectedRoute path="/messages" component={Messages} />
              <Route path="/terms-and-privacy" component={TermsOfUse} />
              <Route path="/about-mentors" component={AboutMentors} />
              <Route component={PageNotFound} />
            </Switch>

            <ToastContainer autoClose={3000} hideProgressBar />
          </main>
          <Footer />
        </div>
      </Suspense>
    </>
  );
}

App.propTypes = {
  userProfile: PropTypes.object.isRequired,
  loadUserProfile: PropTypes.func.isRequired,
  setUserToken: PropTypes.func.isRequired,
  userLogout: PropTypes.func.isRequired,
  userToken: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userProfile: state.userProfile,
    userToken: state.userProfile.token,
  };
}

const mapDispatchToProps = {
  loadUserProfile,
  setUserToken,
  userLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
