import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./components/App";
import "./index.css";
import configureStore from "./redux/configureStore";
import { Provider as ReduxProvider } from "react-redux";
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";
import "./i18n";

import { loadState, saveState } from "./common/localStorage";
import throttle from "lodash.throttle";

const locallyPersistedState = loadState();
const store = configureStore(locallyPersistedState);

//store subscription every 1 second to set localstorage for any piece of redux state we want to persist in local storage.
store.subscribe(
  throttle(() => {
    saveState({
      appPreferences: store.getState().appPreferences,
    });
  }, 1000)
);

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </ReduxProvider>,
  document.getElementById("app")
);
