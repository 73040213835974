import * as types from "./actionTypes";
import * as locationsApi from "../../api/locationsApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadCountriesSuccess(payload) {
  return { type: types.LOCATION_LOAD_COUNTRIES_SUCCESS, payload };
}
export function loadRegionsSuccess(payload) {
  return { type: types.LOCATION_LOAD_REGIONS_SUCCESS, payload };
}

export function loadCountries() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return locationsApi
      .getCountries()
      .then((payload) => {
        dispatch(loadCountriesSuccess(payload));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadRegions(countryId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return locationsApi
      .getRegions(countryId)
      .then((payload) => {
        dispatch(loadRegionsSuccess(payload));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
