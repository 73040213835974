import React from "react";
import CourseContents from "./CourseContents";
import { Progress, Media, ListGroup, ListGroupItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import CourseStartButton from "./CourseStartButton";
import PropTypes from "prop-types";

const CourseListIterator = ({
  filteredCourses,
  userHasCompletedInitialCourse,
  userIsLoggedIn,
  onSignupClick,
  onResumeClick,
  token,
}) => {
  const { t } = useTranslation();
  return (
    <ListGroup className="mb-5 mt-3">
      {filteredCourses.map((course) => {
        return (
          <ListGroupItem
            key={course.code}
            className="px-1 px-md-3"
            color={
              course.isInitialCourse && !userHasCompletedInitialCourse
                ? "success"
                : null
            }
          >
            <Media>
              <Media left className="pr-3">
                <Media
                  object
                  src={
                    process.env.REACT_APP_API_URL +
                    "/images/covers/courses/" +
                    course.code +
                    ".jpg"
                  }
                  alt={course.code}
                  style={{ width: "65px" }}
                  className="rounded"
                />
              </Media>
              <Media
                body
                className="justify-content-between align-items-center"
              >
                <Media heading>
                  {course.title} <br />
                  <div className="small mb-0 mb-md-2">
                    {course.author.name} {course.author.date}
                  </div>
                  {course.isInitialCourse && !userHasCompletedInitialCourse && (
                    <div className="alert alert-warning small mb-0 mb-md-2">
                      {t("course-initial")}
                    </div>
                  )}
                  {course.dateCompleted && (
                    <div className="alert alert-success small mb-0 mb-md-2">
                      {t("course-you-completed")}
                    </div>
                  )}
                  {course.dateCompleted && !course.graderName && (
                    <div className="alert alert-warning small mb-0 mb-md-2">
                      {t("course-no-mentor-assigned")}
                    </div>
                  )}
                  {course.dateCompleted &&
                    course.graderName &&
                    !course.dateGraded && (
                      <div className="alert alert-warning small mb-0 mb-md-2">
                        <strong>{course.graderName}</strong>
                        {t("course-in-grading")}
                      </div>
                    )}
                  {course.dateCompleted && !course.dateGraded && (
                    <div className="alert alert-warning small mb-0 mb-md-2">
                      {t("course-not-yet-graded")}
                    </div>
                  )}
                  {course.dateCompleted && course.dateGraded && (
                    <div className="alert alert-info p-1 mb-0 mb-md-2">
                      <div className="small border-bottom border-info">
                        {t("course-graded", { mentor: course.graderName })}
                      </div>
                      <div style={{ overflowY: "scroll", height: "2.5em" }}>
                        <strong>{course.graderName}</strong> &ldquo;
                        {course.graderComment}&rdquo;
                      </div>
                    </div>
                  )}
                </Media>
                <CourseStartButton
                  course={course}
                  onSignupClick={onSignupClick}
                  onResumeClick={onResumeClick}
                  userHasCompletedInitialCourse={userHasCompletedInitialCourse}
                  userIsLoggedIn={userIsLoggedIn}
                  token={token}
                />{" "}
                <CourseContents course={course} />
                {userIsLoggedIn &&
                  course.countOfQuestionsCompletedInCourse > 0 &&
                  course.countOfQuestionsCompletedInCourse <
                    course.countOfQuestionsInCourse && (
                    <Progress
                      color="primary"
                      value={course.countOfQuestionsCompletedInCourse}
                      max={course.countOfQuestionsInCourse}
                      className="mb-2 mt-2 bg-secondary"
                    >
                      {t("you-have-completed")}{" "}
                      {course.countOfQuestionsCompletedInCourse} {t("of")}{" "}
                      {course.countOfQuestionsInCourse} {t("questions")}{" "}
                      {t("in")} {course.title}.
                    </Progress>
                  )}
              </Media>
            </Media>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
};

CourseListIterator.propTypes = {
  filteredCourses: PropTypes.array.isRequired,
  onSignupClick: PropTypes.func.isRequired,
  onResumeClick: PropTypes.func.isRequired,
  userHasCompletedInitialCourse: PropTypes.bool,
  userIsLoggedIn: PropTypes.bool,
  token: PropTypes.string,
};

export default CourseListIterator;
