import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CourseContentsList from "./CourseContentsList";

const IncompleteCourse = ({ activePart }) => {
  const { t } = useTranslation();

  return (
    <div className="jumbotron">
      <h2>
        {t("completed-not-heading")} <strong>{activePart.course.title}</strong>!
      </h2>
      <div dangerouslySetInnerHTML={{ __html: t("completed-not-text") }} />
      <CourseContentsList
        course={activePart.course}
        activeRowNum={activePart.lesson.numberInCourse}
      />
    </div>
  );
};

IncompleteCourse.propTypes = {
  activePart: PropTypes.object,
};

export default IncompleteCourse;
