import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Spinner from "../components/common/Spinner";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      component={withAuthenticationRequired(Component, {
        //eslint-disable-next-line
        onRedirecting: () => <Spinner />,
      })}
      {...rest}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default ProtectedRoute;
