import React, { useState, useEffect } from "react";
import {
  Card,
  CardText,
  CardHeader,
  CardBody,
  Input,
  Alert,
  Badge,
} from "reactstrap";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter as cf } from "../../common/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faSpinner,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";

const LessonQuestion = ({
  question,
  onSaveResponseClick,
  course,
  userId,
  token,
  setCompleted,
  setAnswersInEditing,
  answersInEditing,
}) => {
  const [answer, setAnswer] = useState({
    token,
    userId,
    courseCode: course.code,
    questionId: question.questionId,
    response: question.usersAnswerContent,
  });
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [thisAnswerInEditing, setThisAnswerInEditing] = useState(0);
  const canAnswer = course.dateGraded == null;

  function formIsValid() {
    const { response } = answer;
    const errors = {};

    if (!response) errors.response = t("response-required");

    setErrors(errors);
    // Form is valid if the errors object still has no properties
    return Object.keys(errors).length === 0;
  }

  function handleSave() {
    if (!formIsValid()) return;
    setSaving(true);
    onSaveResponseClick(answer)
      .then(() => {
        setSaving(false);
        setThisAnswerInEditing(0);
        setAnswersInEditing(answersInEditing - 1);
        if (
          question.numberInCourse === question.numberOfQuestionsInCourse ||
          course.countOfQuestionsCompletedInCourse ===
            course.countOfQuestionsInCourse
        ) {
          setCompleted(true);
        }
      })
      .catch((error) => {
        setErrors({ onSave: error.message });
        setSaving(false);
      });
  }

  useEffect(() => {
    if (answer.response != question.usersAnswerContent) {
      const saveAnswerTimer = setTimeout(() => {
        handleSave();
      }, 1000);
      return () => clearTimeout(saveAnswerTimer);
    }
  }, [answer]);

  function handleChange(event) {
    const { name, value } = event.target;
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      [name]: value,
    }));
    if (thisAnswerInEditing === 0) {
      setThisAnswerInEditing(1);
      setAnswersInEditing(answersInEditing + 1);
    } else if (
      thisAnswerInEditing === 1 &&
      value == question.usersAnswerContent
    ) {
      setThisAnswerInEditing(0);
      setAnswersInEditing(answersInEditing - 1);
    }
  }
  function showSavedStatus() {
    if (!canAnswer) {
      return "";
    }
    if (
      question.usersAnswerContent &&
      answer.response == question.usersAnswerContent
    ) {
      return <em>{t("response-saved")}</em>;
    } else if (question.usersAnswerContent) {
      return <em onClick={handleSave}>{t("response-update")}</em>;
    } else {
      return <em onClick={handleSave}>{t("response-save")}</em>;
    }
  }
  function showMentorMarks() {
    if (canAnswer) return "";
    let wellDone = question.gradeMark == 1 ? true : false;
    return (
      <Alert color={wellDone ? "success" : "danger"}>
        <FontAwesomeIcon icon={faUserEdit} /> <em>{course.graderName}</em>:{" "}
        {question.gradeComment}
        {!question.gradeComment && (
          <small className="text-secondary">
            {t("answer-no-mentor-feedback")}
          </small>
        )}
        {!wellDone && (
          <Badge color="danger" className="float-right">
            {t("answer-wrong")}
          </Badge>
        )}
      </Alert>
    );
  }

  return (
    <div>
      <Card className="mb-2">
        <CardHeader>
          <div
            dangerouslySetInnerHTML={{
              __html:
                "<p>" +
                cf(t("question")) +
                " " +
                question.numberInCourse +
                "</p>" +
                question.questionContent,
            }}
          />
        </CardHeader>
        <CardBody>
          <CardText>
            <FontAwesomeIcon icon={faPen} /> {t("response-your")}{" "}
          </CardText>
          <CardText>
            <Input
              type="textarea"
              id="response"
              name="response"
              label="Response"
              value={answer.response == null ? "" : answer.response}
              onChange={handleChange}
              error={errors.response}
              valid={
                answer.response &&
                answer.response === question.usersAnswerContent
                  ? true
                  : false
              }
              disabled={!canAnswer}
            />
            <small>
              {showSavedStatus()}{" "}
              {saving && <FontAwesomeIcon icon={faSpinner} spin />}{" "}
              {errors.response}
            </small>
          </CardText>
          {showMentorMarks()}
        </CardBody>
        {question.numberInCourse === course.countOfQuestionsInCourse && (
          <Alert color="warning" className="mt-3">
            <small>{t("completed-what-will-happen")}</small>
          </Alert>
        )}
      </Card>
    </div>
  );
};

LessonQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  onSaveResponseClick: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  course: PropTypes.object.isRequired,
  setCompleted: PropTypes.func,
  loadUserProfile: PropTypes.func.isRequired,
  setAnswersInEditing: PropTypes.func.isRequired,
  answersInEditing: PropTypes.number.isRequired,
};

export default LessonQuestion;
