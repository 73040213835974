import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CoursesListFilterBar from "./CoursesListFilterBar";
import CourseListIterator from "./CourseListIterator";

const CourseList = ({
  courses,
  onSignupClick,
  onResumeClick,
  userHasCompletedInitialCourse,
  userIsLoggedIn,
  topics,
  levels,
  token,
}) => {
  const [initialCourses, setInitialCourses] = useState([]);
  const [notInitialCourses, setNotInitialCourses] = useState([]);
  const [filterObject, setFilterObject] = useState({});
  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    setFilteredCourses(notInitialCourses);
  }, [notInitialCourses]);

  useEffect(() => {
    if (userHasCompletedInitialCourse) {
      setNotInitialCourses(courses);
    } else {
      setNotInitialCourses(courses.filter((c) => c.isInitialCourse != true));
      setInitialCourses(courses.filter((c) => c.isInitialCourse == true));
    }
  }, [courses]);
  useEffect(() => {
    if (Object.keys(filterObject).length > 0) {
      setFilteredCourses(
        notInitialCourses.filter(function (item) {
          for (var key in filterObject) {
            if (item[key] === undefined || item[key] != filterObject[key])
              return false;
          }
          return true;
        })
      );
    } else {
      setFilteredCourses(notInitialCourses);
    }
  }, [filterObject]);

  return (
    <>
      <CoursesListFilterBar
        filterObject={filterObject}
        setFilterObject={setFilterObject}
        topics={topics}
        levels={levels}
      />
      {!userHasCompletedInitialCourse && (
        <CourseListIterator
          filteredCourses={initialCourses}
          userHasCompletedInitialCourse={userHasCompletedInitialCourse}
          userIsLoggedIn={userIsLoggedIn}
          onSignupClick={onSignupClick}
          onResumeClick={onResumeClick}
          token={token}
        />
      )}
      <CourseListIterator
        filteredCourses={filteredCourses}
        userHasCompletedInitialCourse={userHasCompletedInitialCourse}
        userIsLoggedIn={userIsLoggedIn}
        onSignupClick={onSignupClick}
        onResumeClick={onResumeClick}
        token={token}
      />
    </>
  );
};

CourseList.propTypes = {
  courses: PropTypes.array.isRequired,
  onSignupClick: PropTypes.func.isRequired,
  onResumeClick: PropTypes.func.isRequired,
  userHasCompletedInitialCourse: PropTypes.bool,
  userIsLoggedIn: PropTypes.bool,
  topics: PropTypes.array.isRequired,
  levels: PropTypes.array.isRequired,
  token: PropTypes.string,
};

export default CourseList;
