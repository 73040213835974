import * as types from "./actionTypes";
import * as mentorApi from "../../api/mentorApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadMentorCourseToGradeSuccess(response) {
  return { type: types.MENTOR_LOAD_COURSE_TO_GRADE_SUCCESS, response };
}
export function loadMentorCourseToGradeFailure(response) {
  return { type: types.MENTOR_LOAD_COURSE_TO_GRADE_FAILURE, response };
}
export function loadMentorCoursesActiveSuccess(response) {
  return { type: types.MENTOR_LOAD_COURSES_ACTIVE_SUCCESS, response };
}
export function loadMentorCoursesPotentialSuccess(response) {
  return { type: types.MENTOR_LOAD_COURSES_POTENTIAL_SUCCESS, response };
}
export function loadMentorCoursesHistorySuccess(response) {
  return { type: types.MENTOR_LOAD_COURSES_HISTORY_SUCCESS, response };
}
export function loadMentorCoursesFailure(response) {
  return { type: types.MENTOR_LOAD_COURSES_FAILURE, response };
}
export function loadAssignCourseToMentorSuccess(response) {
  return { type: types.MENTOR_LOAD_ASSIGN_COURSE_TO_MENTOR_SUCCESS, response };
}
export function loadSaveGraderQuestionCommentSuccess(response) {
  return {
    type: types.MENTOR_LOAD_SAVE_GRADER_QUESTION_COMMENT_SUCCESS,
    response,
  };
}
export function loadSaveGraderCourseCommentSuccess(response) {
  return {
    type: types.MENTOR_LOAD_SAVE_GRADER_COURSE_COMMENT_SUCCESS,
    response,
  };
}

export const loadMentorCourses = (groupId, status, courseId) => {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return mentorApi
      .getMentorCourses(token, groupId, status, courseId)
      .then((response) => {
        switch (status) {
          case "active":
            dispatch(loadMentorCoursesActiveSuccess(response));
            break;
          case "potential":
            dispatch(loadMentorCoursesPotentialSuccess(response));
            break;
          case "history":
            dispatch(loadMentorCoursesHistorySuccess(response));
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};
export const loadMentorCourseToGrade = (groupId, usersCourseId) => {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return mentorApi
      .getMentorCourse(token, groupId, usersCourseId)
      .then((response) => {
        dispatch(loadMentorCourseToGradeSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const loadAssignCourseToMentor = (groupId, usersCourseId) => {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return mentorApi
      .assignCourseToMentor(token, groupId, usersCourseId)
      .then((response) => {
        dispatch(loadAssignCourseToMentorSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export function loadSaveGraderQuestionComment(comment) {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return mentorApi
      .saveGraderQuestionComment(token, comment)
      .then((response) => {
        dispatch(loadSaveGraderQuestionCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadSaveGraderCourseComment(comment) {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return mentorApi
      .saveGraderCourseComment(token, comment)
      .then((response) => {
        dispatch(loadSaveGraderCourseCommentSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
