import * as types from "./actionTypes";
import * as courseApi from "../../api/courseApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadCourseSuccess(courses) {
  return { type: types.LOAD_COURSES_SUCCESS, courses };
}

export function unloadCoursesSuccess() {
  return { type: types.UNLOAD_COURSES };
}

export function loadActiveCourseSuccess(course) {
  return { type: types.LOAD_ACTIVE_COURSE_SUCCESS, course };
}

export function loadInitialCoursesSuccess(courses) {
  return { type: types.LOAD_INITIAL_COURSE_SUCCESS, courses };
}
export function loadMostRecentCourseSuccess(course) {
  return { type: types.LOAD_MOST_RECENT_COURSE_SUCCESS, course };
}
export function loadCourseTopicsSuccess(topics) {
  return { type: types.LOAD_COURSE_TOPICS_SUCCESS, topics };
}
export function loadCourseLevelsSuccess(levels) {
  return { type: types.LOAD_COURSE_LEVELS_SUCCESS, levels };
}
export function createCourseSuccess(course) {
  return { type: types.CREATE_COURSE_SUCCESS, course };
}

export function updateCourseSuccess(course) {
  return { type: types.UPDATE_COURSE_SUCCESS, course };
}

export function deleteCourseOptimistic(course) {
  return { type: types.DELETE_COURSE_OPTIMISTIC, course };
}

export function loadCourses() {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return courseApi
      .getCourses(token)
      .then((courses) => {
        dispatch(loadCourseSuccess(courses));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadActiveCourse(code, token = null) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return courseApi
      .getCourse(code, token)
      .then((course) => {
        dispatch(loadActiveCourseSuccess(course));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadCourseTopics(lang) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return courseApi
      .getCourseTopics(lang)
      .then((topics) => {
        dispatch(loadCourseTopicsSuccess(topics));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
export function loadCourseLevels() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return courseApi
      .getCourseLevels()
      .then((levels) => {
        dispatch(loadCourseLevelsSuccess(levels));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
export function loadInitialCourses(lang) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return courseApi
      .getInitialCourses(lang)
      .then((courses) => {
        dispatch(loadInitialCoursesSuccess(courses));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadMostRecentCourse() {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return courseApi
      .getMostRecentCourse(token)
      .then((course) => {
        dispatch(loadMostRecentCourseSuccess(course));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function unloadCourses() {
  return function (dispatch) {
    dispatch(unloadCoursesSuccess());
  };
}
