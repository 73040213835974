import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LoginButton from "../authentication/login-button";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faFilePdf,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { getCourseCertificate } from "../../api/courseApi";
import { saveAs } from "file-saver";

const CourseStartButton = ({
  course,
  onSignupClick,
  onResumeClick,
  userHasCompletedInitialCourse,
  userIsLoggedIn,
  token,
}) => {
  const { t } = useTranslation();
  const [gettingPdf, setGettingPdf] = useState(false);

  var message;
  message = !userHasCompletedInitialCourse
    ? t("course-must-finish-initial")
    : message;
  message = !userIsLoggedIn ? (
    <>
      <h2>{t("course-must-login")}</h2>
      <p>{t("course-must-login-extra")}</p>
      <LoginButton
        label={t("log-in") + " " + t("or-create-account")}
        redirectTo={"/courses"}
      />
    </>
  ) : (
    message
  );
  var color =
    !userIsLoggedIn ||
    (!userHasCompletedInitialCourse && !course.isInitialCourse)
      ? "secondary"
      : "primary";
  color = course.dateCompleted || course.dateSignedUp ? "success" : color;
  const icon =
    !userIsLoggedIn ||
    (!userHasCompletedInitialCourse && !course.isInitialCourse) ? (
      <>
        <FontAwesomeIcon icon={faLock} />{" "}
      </>
    ) : (
      <></>
    );
  var clickAction =
    !userIsLoggedIn ||
    (!userHasCompletedInitialCourse && !course.isInitialCourse)
      ? () => {
          toast.success(message, { autoClose: 10000 });
        }
      : () => onSignupClick(course);
  clickAction =
    course.dateCompleted || course.dateSignedUp
      ? () => onResumeClick(course)
      : clickAction;
  var buttonText = t("course-start");
  buttonText = course.dateSignedUp ? t("course-resume") : buttonText;
  buttonText = course.dateCompleted ? t("course-review") : buttonText;
  const tryCourseCertificate = () => {
    if (!gettingPdf) {
      setGettingPdf(true);
      getCourseCertificate(course.code, token).then((r) => {
        saveAs(r, course.title + " Certificate.pdf");
        setGettingPdf(false);
      });
    }
  };
  const createCertificateDownloadButton = () => {
    if (course.dateGraded) {
      return (
        <>
          <button
            onClick={tryCourseCertificate}
            className="btn btn-sm btn-success"
          >
            {gettingPdf && <FontAwesomeIcon icon={faSpinner} spin />}
            {!gettingPdf && <FontAwesomeIcon icon={faFilePdf} />}{" "}
            {t("certificate-download")}
          </button>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="float-right">
      {course.dateGraded && (
        <div>
          <small>{t("certificate-explanation")}</small>
        </div>
      )}
      <div>
        {createCertificateDownloadButton()}
        <button className={"btn btn-sm btn-" + color} onClick={clickAction}>
          {icon}
          {buttonText}
        </button>
      </div>
    </div>
  );
};

CourseStartButton.propTypes = {
  course: PropTypes.object,
  onSignupClick: PropTypes.func,
  onResumeClick: PropTypes.func,
  userHasCompletedInitialCourse: PropTypes.bool,
  userIsLoggedIn: PropTypes.bool,
  token: PropTypes.string,
};
export default CourseStartButton;
