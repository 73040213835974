import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import DropDownForInputGroup from "../common/DropDownForInputGroup";

const CoursesListFilterBar = ({
  topics,
  levels,
  filterObject,
  setFilterObject,
}) => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const { t } = useTranslation();
  const onTopicClick = ({ target }) => {
    setSelectedTopic({ name: target.innerText, id: target.value });
    setFilter("topicId", target.value);
  };
  const onLevelClick = ({ target }) => {
    setSelectedLevel({ name: target.innerText, id: target.value });
    setFilter("levelId", target.value);
  };

  const setFilter = (prop, value) => {
    let filter = Object.assign({}, filterObject);
    filter[prop] = value;
    setFilterObject(filter);
  };
  const clearFilter = () => {
    let filter = Object.assign({});
    setFilterObject(filter);
    setSelectedLevel(null);
    setSelectedTopic(null);
  };
  return (
    <InputGroup>
      <DropDownForInputGroup
        errorMessage={""}
        items={topics}
        label={selectedTopic ? selectedTopic.name : t("topic")}
        text="name"
        value="id"
        enabled={true}
        onClick={onTopicClick}
        isValid={true}
        addonType="prepend"
      />
      <DropDownForInputGroup
        errorMessage={""}
        items={levels}
        label={selectedLevel ? selectedLevel.name : "level"}
        text="name"
        value="id"
        enabled={true}
        onClick={onLevelClick}
        isValid={true}
        translateItems
        addonType="append"
      />
      <InputGroupAddon addonType="append">
        <Button onClick={clearFilter}>{t("clear-filter")}</Button>
      </InputGroupAddon>
    </InputGroup>
  );
};

CoursesListFilterBar.propTypes = {
  filterObject: PropTypes.object.isRequired,
  setFilterObject: PropTypes.func.isRequired,
  topics: PropTypes.array.isRequired,
  levels: PropTypes.array.isRequired,
};

export default CoursesListFilterBar;
