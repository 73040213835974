import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const LogoutButton = ({ label, returnTo }) => {
  const { logout } = useAuth0();
  const { t } = useTranslation();

  const text = label ? t(label) : t("log-out");
  const options = returnTo
    ? { returnTo: returnTo }
    : { returnTo: window.location.origin };
  return (
    <DropdownItem onClick={() => logout(options)}>
      <FontAwesomeIcon icon={faSignOutAlt} /> {text}
    </DropdownItem>
  );
};

LogoutButton.propTypes = {
  label: PropTypes.string,
  returnTo: PropTypes.string,
};

export default LogoutButton;
