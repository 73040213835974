import React, { useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { PropTypes } from "prop-types";
import { Redirect } from "react-router-dom";

const BreadCrumb = ({ crumbsArray }) => {
  const [redirect, setRedirect] = useState(null);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Breadcrumb>
      {crumbsArray.map(({ href, text }, i) => {
        return (
          <BreadcrumbItem
            onClick={(event) => {
              event.preventDefault();
              setRedirect(href);
            }}
            tag="a"
            href={href}
            key={i}
          >
            {text}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

BreadCrumb.propTypes = {
  crumbsArray: PropTypes.array,
};

export default BreadCrumb;
