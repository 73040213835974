import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/users/messages";

export function getUserMessages(token) {
  return fetch(baseUrl, {
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}
export function markUserMessageRead(token, data) {
  return fetch(baseUrl, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch((error) => {
      handleError(error);
    });
}
