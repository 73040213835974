import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMap,
  faEnvelope,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="page-footer font-small bg-light-green">
      <div className="container-fluid pt-3">
        <div className="mb-3">
          <FontAwesomeIcon icon={faMap} /> 2603 W Wright St, Pensacola, FL
          32505, USA | <FontAwesomeIcon icon={faEnvelope} />{" "}
          <a href="mailto:school@mountzion.org">school@mountzion.org</a> |{" "}
          <FontAwesomeIcon icon={faMobileAlt} /> +1 850 434 0058
        </div>
        <div className="mb-3">
          <Link to={"/about"}>{t("about")}</Link> |{" "}
          <Link to={"/terms-and-privacy"}>
            {t("terms-of-use-and-privacy-policy")}
          </Link>
        </div>
      </div>
      <div className="footer-copyright text-center py-3 bg-dark-green">
        &copy; {new Date().getFullYear()} {t("mt-zion-bible-institute")},{" "}
        {t("ministry-of")}
      </div>
    </footer>
  );
};

export default Footer;
