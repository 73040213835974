import React, { useState } from "react";
import { PropTypes } from "prop-types";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../common/util";

const MentorCourseCardListItemDetail = ({ userCourse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t, language } = useTranslation();

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret outline>
        <FontAwesomeIcon icon={faInfo} />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem>
          <strong>{t("date-completed")}:</strong>{" "}
          {formatDate(userCourse.dateCompleted, language)}
        </DropdownItem>
        <DropdownItem>
          <strong>{t("date-graded")}:</strong>{" "}
          {formatDate(userCourse.dateGraded, language)}
        </DropdownItem>
        <DropdownItem>
          <strong>{t("date-selected")}:</strong>{" "}
          {formatDate(userCourse.dateGraderSelected, language)}
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

MentorCourseCardListItemDetail.propTypes = {
  userCourse: PropTypes.object.isRequired,
};

export default MentorCourseCardListItemDetail;
