import React from "react";
import "./Spinner.css";
import PropTypes from "prop-types";

const Spinner = ({ text }) => {
  const t = text ? text : "Loading";
  return (
    <>
      <div className="loader">{t}...</div>
      <p style={{ textAlign: "center" }}>{t}...</p>
    </>
  );
};

Spinner.propTypes = {
  text: PropTypes.string,
};

export default Spinner;
