import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function locationsReducer(
  state = initialState.locations,
  action
) {
  switch (action.type) {
    case types.LOCATION_LOAD_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload };
    case types.LOCATION_LOAD_REGIONS_SUCCESS:
      return { ...state, regions: action.payload };
    default:
      return state;
  }
}
