export default {
  courses: [],
  initialCourse: {},
  mostRecentCourse: {},
  courseTopics: [],
  courseLevels: [],
  activeCourse: {},
  activePart: {},
  activeQuestions: [],
  locations: { countries: [], regions: [] },
  userStudentCourses: [],
  userProfile: { triedToLoad: false, dbUser: {} },
  messages: [],
  siteNotice: {},
  apiCallsInProgress: 0,
  mentorCourses: {
    mentorActiveCourses: [],
    mentorPotentialCourses: [],
    mentorHistoryCourses: [],
    mentorCourseToGrade: {},
  },
  appPreferences: {},
};
