import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function questionReducer(
  state = initialState.activeQuestions,
  action
) {
  switch (action.type) {
    case types.LOAD_ACTIVE_PART_SUCCESS:
      return action.part.questions;
    case types.MENTOR_LOAD_COURSE_TO_GRADE_SUCCESS:
      return action.response.usersQuestions;
    case types.MENTOR_LOAD_SAVE_GRADER_QUESTION_COMMENT_SUCCESS:
      return state.map((question) =>
        question.usersQuestionId === action.response.usersQuestionId
          ? action.response
          : question
      );
    case types.USER_SAVE_STUDENT_ANSWER_SUCCESS:
      return state.map((question) =>
        question.questionId === action.response.questionId
          ? action.response
          : question
      );
    default:
      return state;
  }
}
