import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  clearActivePart,
  loadActivePart,
} from "../../redux/actions/lessonActions";
import {
  saveStudentAnswer,
  loadUserProfile,
} from "../../redux/actions/userActions";
import { unloadCourses } from "../../redux/actions/courseActions";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import { Link, Prompt } from "react-router-dom";
import { Redirect } from "react-router";
import LessonQuestion from "./LessonQuestion";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Progress,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter as cf } from "../../common/util";
import ShowCourseTextInReviewButton from "../preferences/ShowCourseTextInReviewButton";
import BreadCrumb from "../common/BreadCrumb";
import CourseContents from "./CourseContents";
import CompletedCourse from "./CompletedCourse";
import IncompleteCourse from "./IncompleteCourse";
import "./LessonDetail.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import { RefTagger } from "react-reftagger";

const LessonDetail = ({
  loadActivePart,
  loadUserProfile,
  clearActivePart,
  saveStudentAnswer,
  unloadCourses,
  activePart,
  activeQuestions,
  dbUser,
  userToken,
  appPreferences,
  match,
}) => {
  const { t } = useTranslation();
  const partId = match.params.partId;
  const courseCode = match.params.courseCode;
  const [redirect, setRedirect] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [confirmedCompleted, setConfirmedCompleted] = useState(false);
  const [lessonLoading, setLessonLoading] = useState(true);
  const [answersInEditing, setAnswersInEditing] = useState(0);

  useEffect(() => {
    if (activePart.course) {
      if (
        activePart.course.countOfQuestionsCompletedInCourse >=
        activePart.course.countOfQuestionsInCourse
      ) {
        setCompleted(true);
      }
    }
  }, [activePart]);

  useEffect(() => {
    if (
      (activePart != undefined && Object.keys(activePart).length === 0) ||
      partId != activePart.partId
    ) {
      setLessonLoading(true);
      loadActivePart(courseCode, partId, userToken).catch((error) => {
        clearActivePart();
        toast.error(t("failure") + error);
        setRedirect("/courses");
      });
    }
  }, [activePart, partId, userToken]);
  useEffect(() => {
    if (
      activePart != undefined &&
      Object.keys(activePart).length > 0 &&
      partId == activePart.partId
    ) {
      setLessonLoading(false);
    }
  });
  useEffect(() => {
    if (answersInEditing > 0) {
      setAnswersInEditing(0);
    }
  }, [match.params.partId]);
  useEffect(() => {
    if (completed) {
      unloadCourses();
    }
  }, [completed]);

  const partOutlineIdentifier = activePart.partOutlineIdentifier
    ? activePart.partOutlineIdentifier
    : "";
  const partOutlineIdentifierWithPeriod = activePart.partOutlineIdentifier
    ? activePart.partOutlineIdentifier + ". "
    : "";
  const partOutlineIdentifierPhrase = activePart.partOutlineIdentifier
    ? cf(t("part")) + " " + partOutlineIdentifier + "."
    : "";
  const RenderProgressBar = () => {
    return (
      <Progress
        color="success"
        value={activePart.course.countOfQuestionsCompletedInCourse}
        max={activePart.course.countOfQuestionsInCourse}
        className="mb-2 bg-secondary"
      >
        {t("you-have-completed")}{" "}
        {activePart.course.countOfQuestionsCompletedInCourse}{" "}
        {t("of", { translate: true })}{" "}
        {activePart.course.countOfQuestionsInCourse} {t("questions")} {t("in")}{" "}
        {activePart.course.title}.
      </Progress>
    );
  };

  const RenderNavButtons = () => {
    const nextPartRoute = Number.isInteger(activePart.nextPartId)
      ? `/course/${courseCode}/part/${activePart.nextPartId}`
      : null;
    const prevPartRoute = Number.isInteger(activePart.previousPartId)
      ? `/course/${courseCode}/part/${activePart.previousPartId}`
      : null;

    return (
      <div className="d-flex justify-content-between align-items-center">
        {activePart.previousPartId && (
          <Link className={"btn btn-primary btn-sm"} to={prevPartRoute}>
            <FontAwesomeIcon icon={faArrowLeft} /> {cf(t("previous"))}
          </Link>
        )}
        {activePart.nextPartId && (
          <Link className={"btn btn-primary btn-sm"} to={nextPartRoute}>
            {cf(t("next"))} <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        )}
      </div>
    );
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (lessonLoading) {
    return (
      <div className="container-fluid shadow px-1 px-md-5 pb-5 bg-light lesson-detail-container">
        <Spinner text={t("loading") + " " + t("lesson")} />
      </div>
    );
  }

  if (confirmedCompleted) {
    if (
      activePart.course.countOfQuestionsCompletedInCourse >
      activePart.course.countOfQuestionsInCourse - 1
    ) {
      return <CompletedCourse courseTitle={activePart.course.title} />;
    } else {
      return <IncompleteCourse activePart={activePart} />;
    }
  }

  return (
    <div className="container-fluid shadow pl-5 pr-5 pb-5 bg-light lesson-detail-container">
      <Prompt
        when={answersInEditing > 0}
        message={t("question-save-before-navigating")}
      />
      <Helmet>
        <title>{activePart.course.title} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <RefTagger bibleVersion={"KJV"} />
      <BreadCrumb
        crumbsArray={[
          { text: t("courses"), href: "/courses" },
          {
            text: activePart.course.title,
            href: "/course/" + activePart.course.code,
          },
          {
            text:
              activePart.lesson.numberInCourse + ". " + activePart.lesson.title,
          },
          { text: partOutlineIdentifierWithPeriod + activePart.title },
        ]}
      />
      <CourseContents
        course={activePart.course}
        activeRowNum={activePart.lesson.numberInCourse}
        hidePdf
        className="float-right"
      />
      <h2>
        {cf(t("lesson")) +
          " " +
          activePart.lesson.numberInCourse +
          ". " +
          partOutlineIdentifierPhrase}
      </h2>
      <h1 className="display-4">{activePart.title}</h1>
      {RenderProgressBar()}
      {activePart.course.dateGraded && (
        <ShowCourseTextInReviewButton className="float-right btn-sm" />
      )}
      {RenderNavButtons()}{" "}
      <Row className="mt-4">
        {(!activePart.course.dateGraded ||
          appPreferences.showTextWhenReviewLesson) && (
          <Col md="6" xl="8">
            <div
              className="part-content"
              dangerouslySetInnerHTML={{ __html: activePart.content }}
            />
            {RenderNavButtons()}
          </Col>
        )}
        <Col md="6" xl="4">
          <Card
            style={{
              position: "sticky",
              top: "0px",
              height: "99vh",
              overflowY: "scroll",
            }}
            outline
            color="success"
          >
            <CardHeader>
              <h2>
                {t("lesson-questions", {
                  lesson: activePart.lesson.numberInCourse,
                }) +
                  " " +
                  partOutlineIdentifier}
              </h2>
            </CardHeader>
            <Alert color="warning">
              <small>{t("lesson-questions-instructions")}</small>
            </Alert>

            {activeQuestions.length > 0 &&
              activeQuestions.map((question) => (
                <LessonQuestion
                  question={question}
                  key={question.questionId}
                  onSaveResponseClick={saveStudentAnswer}
                  course={activePart.course}
                  userId={dbUser.userId}
                  token={userToken}
                  history={history}
                  loadUserProfile={loadUserProfile}
                  setCompleted={setCompleted}
                  setAnswersInEditing={setAnswersInEditing}
                  answersInEditing={answersInEditing}
                />
              ))}
            {activeQuestions.length < 1 && (
              <CardBody>
                <Alert color="info">{t("questions-none")}</Alert>
              </CardBody>
            )}

            <CardFooter>
              {RenderProgressBar()}
              {RenderNavButtons()}
              {completed && (
                <Button
                  onClick={() => {
                    setConfirmedCompleted(true);
                  }}
                  className="btn btn-lg btn-success col mt-3"
                >
                  {t("completed-button")}
                </Button>
              )}
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

LessonDetail.propTypes = {
  match: PropTypes.object.isRequired,
  activePart: PropTypes.object.isRequired,
  activeQuestions: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  loadActivePart: PropTypes.func.isRequired,
  loadUserProfile: PropTypes.func.isRequired,
  unloadCourses: PropTypes.func.isRequired,
  clearActivePart: PropTypes.func.isRequired,
  saveStudentAnswer: PropTypes.func.isRequired,
  dbUser: PropTypes.object.isRequired,
  userToken: PropTypes.string.isRequired,
  appPreferences: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    activePart: state.activePart,
    activeQuestions: state.activeQuestions,
    dbUser: state.userProfile.dbUser,
    userToken: state.userProfile.token,
    appPreferences: state.appPreferences,
  };
}

const mapDispatchToProps = {
  loadActivePart,
  clearActivePart,
  saveStudentAnswer,
  loadUserProfile,
  unloadCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetail);
