import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const AboutMentors = () => {
  const { t } = useTranslation(["translation", "about"]);

  return (
    <div
      className="container-fluid shadow p-1 p-md-5 bg-light rounded"
      style={{ maxWidth: "850px" }}
    >
      <Helmet>
        <title>{t("about") + " " + t("mentors")}</title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="display-4">{t("about") + " " + t("mentors")}</h1>

      <div dangerouslySetInnerHTML={{ __html: t("about:mentor-body") }} />
    </div>
  );
};

export default AboutMentors;
