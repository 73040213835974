import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalPopup = (props) => {
  const { buttonLabel, className, color, headerText, bodyText, closeText } =
    props;

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  return (
    <div>
      <Button color={color || "secondary"} onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: bodyText }} />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            {closeText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ModalPopup.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  closeText: PropTypes.string,
};

export default ModalPopup;
