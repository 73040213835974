import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  loadMentorCourseToGrade,
  loadSaveGraderQuestionComment,
  loadSaveGraderCourseComment,
} from "../../redux/actions/mentorActions";
import { Redirect } from "react-router-dom";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import MentorQuestion from "./MentorQuestion";
import MentorCourseCommentForm from "./MentorCourseCommentForm";
import { Button, Card, Col, Row } from "reactstrap";
import { capitalizeFirstLetter as cf } from "../../common/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const MentorGradeCourse = ({
  dbUser,
  course,
  activeQuestions,
  loadMentorCourseToGrade,
  loadSaveGraderQuestionComment,
  loadSaveGraderCourseComment,
  match,
}) => {
  const { t } = useTranslation(["translation", "mentor"]);
  const [isLoading, setIsLoading] = useState(true);
  const groupId = match.params.groupId;
  const userCourseId = match.params.userCourseId;
  const [redirect, setRedirect] = useState(null);
  const [partReadingText, setPartReadingText] = useState({});

  useEffect(() => {
    if (dbUser.userId && !dbUser.isGrader) {
      setRedirect("/courses");
    } else if (course.usersCourseId) {
      setIsLoading(false);
    } else if (isLoading) {
      loadMentorCourseToGrade(groupId, userCourseId).catch((error) => {
        toast.error(t("failure") + error);
      });
    }
  }, [dbUser, course, isLoading]);

  const tryPartReadingText = (lessonId, partId) => {
    const lesson = course.lessons.filter((l) => l.lessonId == lessonId)[0];
    const part = lesson.parts.filter((p) => p.partId == partId)[0];
    const numOfQuestions = part.questions.length;

    const questionNumsList = part.questions.map(({ numberInCourse }, i) => {
      const and = numOfQuestions > 1 && i + 1 == numOfQuestions ? " and " : "";
      return and + numberInCourse;
    });
    const qText = numOfQuestions > 1 ? "questions" : "question";
    const areText =
      numOfQuestions > 1 ? "based-on-this-text-are" : "based-on-this-text-is";
    const questionsNumsText =
      cf(t(qText)) + " " + questionNumsList + " " + t(areText);
    const partText = {
      title: part.title,
      content: part.content,
      questionNumsList,
      questionsNumsText,
    };

    setPartReadingText(partText);
  };
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (isLoading) {
    return <Spinner text={t("loading") + " " + t("student-resources")} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("grading")} {course.courseTitle} {t("for")} {course.studentName} -
          MZBI
        </title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="mt-5 mb-5">
        {t("grading")} <em>{course.courseTitle}</em> {t("for")}{" "}
        {course.studentName}
      </h1>

      <Row className="mt-4">
        {partReadingText.content && (
          <Col md="6" xl="8">
            <Card
              style={{
                position: "sticky",
                top: "0px",
                height: "99vh",
                overflowY: "scroll",
              }}
              outline
              color="success"
            >
              <p>
                {partReadingText.questionsNumsText}
                <Button
                  onClick={() => setPartReadingText({})}
                  size="sm"
                  color="info"
                  className="float-right"
                >
                  <FontAwesomeIcon icon={faEyeSlash} />{" "}
                  {t("mentor:mentor-hide-text")}
                </Button>
              </p>

              <h2>{partReadingText.title}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: partReadingText.content }}
              />
            </Card>
          </Col>
        )}
        <Col>
          {activeQuestions.map((usersQuestion) => {
            return (
              <MentorQuestion
                question={usersQuestion}
                key={usersQuestion.usersQuestionId}
                onSaveResponseClick={loadSaveGraderQuestionComment}
                groupId={groupId}
                tryPartReadingText={tryPartReadingText}
                studentName={course.studentName}
              />
            );
          })}
          <MentorCourseCommentForm
            onSaveResponseClick={loadSaveGraderCourseComment}
            groupId={groupId}
            course={course}
            questions={activeQuestions}
            setRedirect={setRedirect}
          />
        </Col>
      </Row>
    </>
  );
};

MentorGradeCourse.propTypes = {
  dbUser: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  activeQuestions: PropTypes.array.isRequired,
  loadMentorCourseToGrade: PropTypes.func.isRequired,
  loadSaveGraderCourseComment: PropTypes.func.isRequired,
  loadSaveGraderQuestionComment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    loadMentorCourseToGrade: (groupId, userCourseId) =>
      dispatch(loadMentorCourseToGrade(groupId, userCourseId)),
    loadSaveGraderQuestionComment: (commentObject) =>
      dispatch(loadSaveGraderQuestionComment(commentObject)),
    loadSaveGraderCourseComment: (commentObject) =>
      dispatch(loadSaveGraderCourseComment(commentObject)),
  };
}

const mapStateToProps = (state) => ({
  dbUser: state.userProfile.dbUser,
  course: state.mentorCourses.mentorCourseToGrade,
  activeQuestions: state.activeQuestions,
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorGradeCourse);
