import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t("404-header")} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <h1>{t("404-header")}</h1>
      <p>{t("404-body")}</p>
    </>
  );
};
export default PageNotFound;
