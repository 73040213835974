import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function messageReducer(state = initialState.messages, action) {
  switch (action.type) {
    case types.MESSAGES_LOAD_SUCCESS:
      return action.response;
    case types.MESSAGE_UPDATE_SUCCESS:
      return state.map((message) =>
        message.id === action.response.id ? action.response : message
      );
    default:
      return state;
  }
}
