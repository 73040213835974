import React, { useState, useEffect } from "react";
import AuthenticationButton from "./authentication-button";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const AuthNav = () => {
  const { user, isAuthenticated } = useAuth0();
  const [_user, setUser] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated) {
      setUser(user);
    }
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <Dropdown
        isOpen={dropdownOpen}
        className="ml-auto"
        toggle={() => {
          setDropdownOpen(!dropdownOpen);
        }}
        nav
      >
        <DropdownToggle caret className="text-light" nav>
          <img src={_user.picture} width="23px" className="rounded-circle" />{" "}
          <div className="d-none d-lg-inline">{_user.name}</div>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag={Link} to="/user-profile">
            <FontAwesomeIcon icon={faUser} /> {t("user-profile")}
          </DropdownItem>
          <AuthenticationButton />
        </DropdownMenu>
      </Dropdown>
    );
  }

  return (
    !isAuthenticated && (
      <div className="ml-auto">
        <AuthenticationButton />
      </div>
    )
  );
};

export default AuthNav;
