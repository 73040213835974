import React from "react";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import LogoutButton from "../authentication/logout-button";
import { Helmet } from "react-helmet";

const UserProfileBlock = ({ situation }) => {
  const { t } = useTranslation();

  if (situation == "emailAlreadyTaken") {
    return (
      <Card className="mt-5 mb-5">
        <Helmet>
          <title>{t("user-email-already-used-header")} - MZBI</title>
          <meta name="description" content="" />
        </Helmet>
        <CardHeader>
          <h1>{t("user-email-already-used-header")}</h1>
        </CardHeader>
        <CardBody
          dangerouslySetInnerHTML={{
            __html: t("user-email-already-used-text"),
          }}
        ></CardBody>
        <CardFooter>
          <LogoutButton label="user-email-already-used-action" />
        </CardFooter>
      </Card>
    );
  }

  return <></>;
};

UserProfileBlock.propTypes = {
  situation: PropTypes.string,
};

export default UserProfileBlock;
