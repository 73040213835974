import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/courses/";

export function getAllLessons(courseCode) {
  return fetch(baseUrl + courseCode + "/lessons/")
    .then(handleResponse)
    .catch(handleError);
}

export function getActivePart(courseCode, partId, token) {
  const headers = token !== null ? { Authorization: "Bearer " + token } : {};
  return fetch(baseUrl + courseCode + "/parts/" + partId, {
    headers: headers,
  })
    .then(handleResponse)
    .catch(handleError);
}
