import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CourseContentsList from "./CourseContentsList";

const CourseContents = ({ course, activeRowNum, hidePdf, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  return (
    <div>
      <button
        onClick={toggle}
        className={"btn btn-sm btn-secondary " + className}
      >
        {isOpen && t("close")}
        {!isOpen && t("course-contents")}
      </button>
      <Collapse isOpen={isOpen}>
        {hidePdf && <h3>{course.title}</h3>}
        <CourseContentsList
          course={course}
          activeRowNum={activeRowNum}
          toggle={toggle}
        />
        <p className="mt-3">{course.description}</p>
        {!hidePdf && (
          <>
            <a
              href={
                "https://www.chapellibrary.org:8443/pdf/courses/" +
                course.code +
                "-sg.pdf"
              }
              target="blank"
              className="btn btn-sm btn-outline-success"
            >
              <FontAwesomeIcon icon={faFilePdf} /> {t("pdf-study-guide")}
            </a>
            <p className="mb-3">
              <small>
                <FontAwesomeIcon icon={faInfoCircle} /> {t("pdf-for-reference")}
              </small>
            </p>
          </>
        )}
      </Collapse>
    </div>
  );
};

CourseContents.propTypes = {
  course: PropTypes.object.isRequired,
  hidePdf: PropTypes.bool,
  activeRowNum: PropTypes.number,
  className: PropTypes.string,
};

export default CourseContents;
