import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/mentorgroups/";

export function getMentorCourses(token, groupId, status, courseId = null) {
  return fetch(
    baseUrl +
      groupId +
      "?status=" +
      status +
      (courseId ? "&courseId=" + courseId : ""),
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function getMentorCourse(token, groupId, usersCourseId) {
  return fetch(baseUrl + groupId + "/userscourse/" + usersCourseId, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function assignCourseToMentor(token, groupId, usersCourseId) {
  return fetch(
    baseUrl + groupId + "/userscourse/" + usersCourseId + "/graderassign",
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function saveGraderCourseComment(token, feedbackObject) {
  const _comment = {
    graderCourseComment: feedbackObject.gradeComment,
  };
  return fetch(
    baseUrl +
      feedbackObject.groupId +
      "/userscourse/" +
      feedbackObject.usersCourseId,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(_comment),
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function saveGraderQuestionComment(token, feedbackObject) {
  const _comment = {
    gradeComment: feedbackObject.gradeComment,
    gradeMark: feedbackObject.gradeMark,
  };
  return fetch(
    baseUrl +
      feedbackObject.groupId +
      "/userscourse/" +
      feedbackObject.usersCourseId +
      "/usersquestion/" +
      feedbackObject.usersQuestionId,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(_comment),
    }
  )
    .then(handleResponse)
    .catch(handleError);
}
