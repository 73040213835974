import { INPUT_TYPES } from "./constants";

const isValidEmail = (input) => {
  //eslint-disable-next-line
  const regex =
    //eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(input).toLowerCase());
};

const isValidName = (input) => {
  const regex = /^\D+$/;
  return regex.test(String(input).toLowerCase());
};
const isValidCurrency = (input) => {
  const regex = /^$|^(\d*\.)?\d+$/;
  return regex.test(String(input));
};

const isValidPhone = (input) => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return regex.test(String(input));
};

const isValidYear = (input) => {
  const regex = /^(\d{4})$/;
  return regex.test(String(input));
};

export const validateInput = (input, inputType) => {
  switch (inputType) {
    case INPUT_TYPES.EMAIL:
      return isValidEmail(input);
    case INPUT_TYPES.NAME:
      return isValidName(input);
    case INPUT_TYPES.CURRENCY:
      return isValidCurrency(input);
    case INPUT_TYPES.PHONE:
      return isValidPhone(input);
    case INPUT_TYPES.YEAR:
      return isValidYear(input);
    default:
      return true;
  }
};

export const capitalizeFirstLetter = (str) => {
  return str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const formatDate = (date, language, options = {}) => {
  var d = date != null ? new Date(date) : null;
  return d != null ? d.toLocaleDateString(language, options) : "";
};
export const formatTime = (date, language, options = {}) => {
  var d = date != null ? new Date(date) : null;
  return d != null ? d.toLocaleTimeString(language, options) : "";
};
export const colorRandomizer = () => {
  let colors = ["secondary", "primary", "success", "warning", "info", "danger"];
  let color = colors[Math.floor(Math.random() * colors.length)];
  return color;
};
