import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";

const CompletedCourse = ({ courseTitle }) => {
  const { t } = useTranslation();

  return (
    <div className="jumbotron">
      <h2>
        {t("completed-heading")} <strong>{courseTitle}</strong>!
      </h2>
      <div dangerouslySetInnerHTML={{ __html: t("completed-text") }} />
      <div>
        <Link to="/courses" className="btn btn-primary btn-lg mr-3">
          {t("sign-up-another")}
        </Link>
        <Button
          onClick={() => {
            window.location.reload();
          }}
          className="btn btn-primary btn-lg"
        >
          {t("go-back-to-course")}
        </Button>
      </div>
    </div>
  );
};

CompletedCourse.propTypes = {
  courseTitle: PropTypes.string.isRequired,
};

export default CompletedCourse;
