import React from "react";
import { setShowTextWhenReviewLesson } from "../../redux/actions/appPreferenceActions";
import { PropTypes } from "prop-types";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ShowCourseTextInReviewButton = ({
  setShowTextWhenReviewLesson,
  appPreferences,
  className,
}) => {
  const { t } = useTranslation();
  const text = appPreferences.showTextWhenReviewLesson
    ? "pref-lesson-text-in-review-hide"
    : "pref-lesson-text-in-review-show";
  const faIcon = appPreferences.showTextWhenReviewLesson ? faEyeSlash : faEye;
  return (
    <Button
      className={className}
      onClick={() => {
        setShowTextWhenReviewLesson(
          appPreferences.showTextWhenReviewLesson ? false : true
        );
      }}
    >
      <FontAwesomeIcon icon={faIcon} /> {t(text)}
    </Button>
  );
};

ShowCourseTextInReviewButton.propTypes = {
  setShowTextWhenReviewLesson: PropTypes.func.isRequired,
  appPreferences: PropTypes.object.isRequired,
  className: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    appPreferences: state.appPreferences,
  };
}

const mapDispatchToProps = {
  setShowTextWhenReviewLesson,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowCourseTextInReviewButton);
