import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Alert,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faQuestion,
  faSpinner,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter as cf } from "../../common/util";
import DropDownForInputGroup from "../common/DropDownForInputGroup";

const MentorQuestion = ({
  question,
  onSaveResponseClick,
  groupId,
  tryPartReadingText,
  studentName,
}) => {
  const [commentObject, setCommentObject] = useState({
    groupId,
    questionId: question.questionId,
    usersQuestionId: question.usersQuestionId,
    usersCourseId: question.usersCourseId,
    gradeMark: question.gradeMark,
    gradeComment: question.gradeComment,
  });
  const { t } = useTranslation(["translation", "mentor"]);
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);
  const [marks] = useState([
    { id: 0, name: "no" },
    { id: 1, name: "yes" },
  ]);
  const [validMark, setValidMark] = useState(true);
  const [selectedMark, setSelectedMark] = useState(null);
  const [gradeMarkColor, setGradeMarkColor] = useState("secondary");

  useEffect(() => {
    if (question.gradeMark != null && marks) {
      let color = question.gradeMark == 1 ? "success" : "danger";
      let mark = marks.filter((m) => m.id == question.gradeMark)[0];
      setSelectedMark({ name: mark.name, id: mark.id });
      setGradeMarkColor(color);
    }
  }, [question, marks]);

  function formIsValid() {
    const { gradeMark } = commentObject;
    const _errors = {};
    if (gradeMark != 0 && gradeMark != 1) {
      setValidMark(false);
      _errors.gradeMark = "mentor:mentor-must-mark";
      setErrors(_errors);
    }
    return Object.keys(_errors).length === 0;
  }

  function handleSave() {
    let color = commentObject.gradeMark == 1 ? "success" : "danger";
    if (!formIsValid()) return;
    setSaving(true);
    onSaveResponseClick(commentObject)
      .then(() => {
        setSaving(false);
        setGradeMarkColor(color);
        setErrors({});
      })
      .catch((error) => {
        setErrors({ onSave: error.message });
        setSaving(false);
      });
  }

  useEffect(() => {
    if (
      commentObject.gradeMark != question.gradeMark ||
      commentObject.gradeComment != question.gradeComment
    ) {
      const saveCommentObjectTimer = setTimeout(() => {
        handleSave();
      }, 1000);
      return () => clearTimeout(saveCommentObjectTimer);
    }
  }, [commentObject]);

  function handleChange(event) {
    const { name, value } = event.target;
    setCommentObject((prevCommentObject) => ({
      ...prevCommentObject,
      [name]: value,
    }));
  }
  const onMarkClick = ({ target }) => {
    const name = target.innerText;
    const value = target.value;
    setCommentObject((prevCommentObject) => ({
      ...prevCommentObject,
      gradeMark: value,
    }));
    setSelectedMark({ name: name, id: value });
    setValidMark(true);
    setGradeMarkColor("info");
  };
  function showSavedStatus() {
    if (
      (question.gradeMark == 0 || question.gradeMark == 1) &&
      commentObject.gradeMark == question.gradeMark &&
      commentObject.gradeComment == question.gradeComment
    ) {
      return <em>{t("response-saved")}</em>;
    } else if (question.gradeMark == 0 || question.gradeMark == 1) {
      return <em onClick={handleSave}>{t("response-update")}</em>;
    } else {
      return (
        <em size="sm" color="primary" onClick={handleSave}>
          {t("response-save")}{" "}
        </em>
      );
    }
  }

  const partOutlineIdentifierPhrase = question.partOutlineIdentifier
    ? cf(t("part")) + " " + question.partOutlineIdentifier + ")"
    : "";

  return (
    <>
      <Card className="mb-3">
        <CardHeader className="bg-info text-light">
          <Button
            color="outline-light"
            size="sm"
            className="float-right"
            onClick={() =>
              tryPartReadingText(question.lessonId, question.partId)
            }
          >
            {t("mentor:mentor-show-text")}
          </Button>
          <h5>
            {cf(t("question")) +
              " " +
              question.numberInCourse +
              " (" +
              cf(t("lesson")) +
              " " +
              question.lessonNumInCourse +
              " " +
              partOutlineIdentifierPhrase}
          </h5>
        </CardHeader>
        <CardBody>
          <div>
            <div className="mb-2">
              <FontAwesomeIcon icon={faQuestion} className="mr-2" />
              <span
                dangerouslySetInnerHTML={{ __html: question.questionContent }}
              />
            </div>
            <div className="mb-2">
              <FontAwesomeIcon icon={faBook} className="mr-2" />
              <span
                dangerouslySetInnerHTML={{ __html: question.answerContent }}
              />
            </div>
          </div>

          <Alert color="success" className="mb-0">
            <FontAwesomeIcon icon={faUserEdit} className="mr-2" /> {studentName}
            : {question.response}
          </Alert>
        </CardBody>
        <CardFooter>
          <Row>
            <InputGroup>
              <DropDownForInputGroup
                items={marks}
                label={
                  selectedMark
                    ? t(selectedMark.name)
                    : t("mentor:mentor-answered-correctly")
                }
                text="name"
                value="id"
                addonType="prepend"
                color={gradeMarkColor}
                enabled={true}
                onClick={onMarkClick}
                isValid={validMark}
                translateItems
              />
              <Input
                id="gradeComment"
                name="gradeComment"
                label="Grade comment"
                type={"textarea"}
                rows={commentObject.gradeComment.length > 100 ? 3 : 1}
                value={commentObject.gradeComment + ""}
                onChange={handleChange}
                error={errors.gradeComment}
                valid={
                  !!(
                    commentObject.gradeComment &&
                    commentObject.gradeComment == question.gradeComment
                  )
                }
                placeholder={t("mentor:mentor-must-comment")}
              />
              <InputGroupAddon addonType="append">
                <Button className="btn-sm">
                  {showSavedStatus()}
                  {saving && <FontAwesomeIcon icon={faSpinner} spin />}
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

MentorQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  onSaveResponseClick: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  tryPartReadingText: PropTypes.func,
  studentName: PropTypes.string,
};

export default MentorQuestion;
