import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupButtonDropdown,
} from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DropDownForInputGroup = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const validationMessage = props.errorMessage
    ? props.errorMessage.replace("#", props.label)
    : "";
  const { t } = useTranslation();
  const _t = (text) => {
    if (props.translateItems) {
      return t(text);
    } else {
      return text;
    }
  };
  const renderItems = (items) => {
    if (items) {
      return items.map((item, i) => (
        <DropdownItem
          key={i}
          onClick={props.onClick}
          value={props.value ? item[props.value] : item}
          className={
            props.separatedIndex && i === props.separatedIndex - 1
              ? "separatedIndex"
              : ""
          }
        >
          {props.text ? _t(item[props.text]) : _t(item)}
        </DropdownItem>
      ));
    }
  };

  return (
    <>
      <InputGroupButtonDropdown
        className={props.className}
        disabled={!props.enabled}
        addonType={props.addonType}
        toggle={toggleDropDown}
        isOpen={dropdownOpen}
      >
        <DropdownToggle
          caret
          color={props.color ? props.color : "primary"}
          disabled={!props.enabled}
          className="full-width"
        >
          {_t(props.label)}
        </DropdownToggle>
        <DropdownMenu style={{ width: "inherit" }} modifiers={props.modifiers}>
          {renderItems(props.items)}
        </DropdownMenu>
        <div
          className="invalid-feedback"
          style={{
            display: props.isValid && validationMessage ? "none" : "block",
          }}
        >
          {validationMessage}
        </div>
      </InputGroupButtonDropdown>
    </>
  );
};

DropDownForInputGroup.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  separatedIndex: PropTypes.number,
  text: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  enabled: PropTypes.bool,
  label: PropTypes.string,
  modifiers: PropTypes.object,
  items: PropTypes.array,
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
  translateItems: PropTypes.bool,
  addonType: PropTypes.string,
};

export default DropDownForInputGroup;
