import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function courseReducer(
  state = initialState.activeCourse,
  action
) {
  switch (action.type) {
    case types.LOAD_ACTIVE_COURSE_SUCCESS:
      return action.course;
    default:
      return state;
  }
}
