import React, { useState } from "react";
import { Flags } from "../../common/flags";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const LanguageSelector = ({ inNav }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const { i18n, t } = useTranslation();
  const currentLang = i18n.language.substr(0, 2);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const langs = ["en", "es"];
  let classString = "text-light";
  classString = inNav ? classString : classString + " bg-light-green";

  return (
    <Dropdown nav={inNav} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle nav={inNav} caret className={classString}>
        <img
          src={Flags[currentLang.toUpperCase()]}
          alt={t("language-" + currentLang)}
          width="16"
          height="16"
        ></img>{" "}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header>{t("site-language")}</DropdownItem>
        {langs.map((lang, i) => (
          <DropdownItem
            key={i}
            onClick={() => {
              changeLanguage(lang);
            }}
          >
            <a className="btn">
              <img
                src={Flags[lang.toUpperCase()]}
                alt={t("language-" + lang)}
                lang={lang}
                width="16"
                height="16"
              ></img>{" "}
              {t("language-" + lang)}
            </a>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

LanguageSelector.propTypes = {
  inNav: PropTypes.bool,
};

export default LanguageSelector;
