export const CREATE_COURSE = "CREATE_COURSE";
export const LOAD_COURSES_SUCCESS = "LOAD_COURSES_SUCCESS";
export const UNLOAD_COURSES = "UNLOAD_COURSES";
export const LOAD_INITIAL_COURSE_SUCCESS = "LOAD_INITIAL_COURSE_SUCCESS";
export const LOAD_MOST_RECENT_COURSE_SUCCESS =
  "LOAD_MOST_RECENT_COURSE_SUCCESS";
export const LOAD_ACTIVE_COURSE_SUCCESS = "LOAD_ACTIVE_COURSE_SUCCESS";
export const LOAD_COURSE_TOPICS_SUCCESS = "LOAD_COURSE_TOPICS_SUCCESS";
export const LOAD_COURSE_LEVELS_SUCCESS = "LOAD_COURSE_LEVELS_SUCCESS";
export const LOAD_SITE_Notice = "LOAD_SITE_Notice";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_ACTIVE_PART_SUCCESS = "LOAD_ACTIVE_PART_SUCCESS";
export const INCREMENT_ACTIVE_PART_COURSE_QS_COMPLETED =
  "INCREMENT_ACTIVE_PART_COURSE_QS_COMPLETED";
export const CLEAR_ACTIVE_PART = "CLEAR_ACTIVE_PART";
export const LOCATION_LOAD_COUNTRIES_SUCCESS =
  "LOCATION_LOAD_COUNTRIES_SUCCESS";
export const LOCATION_LOAD_REGIONS_SUCCESS = "LOCATION_LOAD_REGIONS_SUCCESS";
export const MENTOR_LOAD_COURSES_ACTIVE_SUCCESS =
  "MENTOR_LOAD_COURSES_ACTIVE_SUCCESS";
export const MENTOR_LOAD_COURSES_POTENTIAL_SUCCESS =
  "MENTOR_LOAD_COURSES_POTENTIAL_SUCCESS";
export const MENTOR_LOAD_COURSES_HISTORY_SUCCESS =
  "MENTOR_LOAD_COURSES_HISTORY_SUCCESS";
export const MENTOR_LOAD_COURSES_FAILURE = "MENTOR_LOAD_COURSES_FAILURE";
export const MENTOR_LOAD_COURSE_TO_GRADE_SUCCESS =
  "MENTOR_LOAD_COURSE_TO_GRADE_SUCCESS";
export const MENTOR_LOAD_COURSE_TO_GRADE_FAILURE =
  "MENTOR_LOAD_COURSE_TO_GRADE_FAILURE";
export const MENTOR_LOAD_ASSIGN_COURSE_TO_MENTOR_SUCCESS =
  "MENTOR_LOAD_ASSIGN_COURSE_TO_MENTOR_SUCCESS";
export const MENTOR_LOAD_ASSIGN_COURSE_TO_MENTOR_FAILURE =
  "MENTOR_LOAD_ASSIGN_COURSE_TO_MENTOR_FAILURE";
export const MENTOR_LOAD_SAVE_GRADER_QUESTION_COMMENT_SUCCESS =
  "MENTOR_LOAD_SAVE_GRADER_COMMENT_SUCCESS";
export const MENTOR_LOAD_SAVE_GRADER_QUESTION_COMMENT_FAILURE =
  "MENTOR_LOAD_SAVE_GRADER_COMMENT_FAILURE";
export const MENTOR_LOAD_SAVE_GRADER_COURSE_COMMENT_SUCCESS =
  "MENTOR_LOAD_SAVE_GRADER_COURSE_COMMENT_SUCCESS";
export const MENTOR_LOAD_SAVE_GRADER_COURSE_COMMENT_FAILURE =
  "MENTOR_LOAD_SAVE_GRADER_COURSE_COMMENT_FAILURE";
export const MESSAGES_LOAD_SUCCESS = "MESSAGES_LOAD_SUCCESS";
export const MESSAGE_UPDATE_SUCCESS = "MESSAGE_UPDATE_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOAD_PROFILE_SUCCESS = "USER_LOAD_PROFILE_SUCCESS";
export const USER_LOAD_PROFILE_FAILURE = "USER_LOAD_PROFILE_FAILURE";
export const USER_LOAD_TOKEN_SUCCESS = "USER_LOAD_TOKEN_SUCCESS";
export const USER_ENROLL_IN_COURSE_SUCCESS = "USER_ENROLL_IN_COURSE_SUCCESS";
export const USER_SAVE_STUDENT_ANSWER_SUCCESS =
  "USER_SAVE_STUDENT_ANSWER_SUCCESS";
export const USER_LOAD_STUDENT_COURSES_SUCCESS =
  "USER_LOAD_STUDENT_COURSES_SUCCESS";
export const USER_LOAD_GRADER_COURSES_SUCCESS =
  "USER_LOAD_GRADER_COURSES_SUCCESS";
export const SET_PREF_REVIEW_TEXT = "SET_PREF_REVIEW_TEXT";

export const DELETE_COURSE_OPTIMISTIC = "DELETE_COURSE_OPTIMISTIC";
