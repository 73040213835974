import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function mentorCourses(
  state = initialState.mentorCourses,
  action
) {
  switch (action.type) {
    case types.MENTOR_LOAD_COURSES_ACTIVE_SUCCESS:
      return { ...state, mentorActiveCourses: action.response };
    case types.MENTOR_LOAD_COURSES_POTENTIAL_SUCCESS:
      return { ...state, mentorPotentialCourses: action.response };
    case types.MENTOR_LOAD_COURSES_HISTORY_SUCCESS:
      return { ...state, mentorHistoryCourses: action.response };
    case types.MENTOR_LOAD_ASSIGN_COURSE_TO_MENTOR_SUCCESS:
      return { ...state, mentorActiveCourses: action.response };
    case types.MENTOR_LOAD_SAVE_GRADER_COURSE_COMMENT_SUCCESS:
      return { ...state, mentorCourseToGrade: {} };
    case types.MENTOR_LOAD_COURSE_TO_GRADE_SUCCESS:
      return { ...state, mentorCourseToGrade: action.response };
    default:
      return state;
  }
}
