import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ContinueActiveCourseCard from "../courses/ContinueActiveCourseCard";
import InitialCourseCard from "../courses/InitialCourseCard";

const HomeActionCard = ({ userHasStartedACourse }) => {
  if (userHasStartedACourse) {
    return <ContinueActiveCourseCard />;
  }
  return <InitialCourseCard />;
};
HomeActionCard.propTypes = {
  userHasStartedACourse: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userHasStartedACourse: state.userProfile.dbUser.hasStartedACourse,
});

export default connect(mapStateToProps)(HomeActionCard);
