import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { loadActiveCourse } from "../../redux/actions/courseActions";
import Spinner from "../common/Spinner";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { capitalizeFirstLetter as cf } from "../../common/util";
import BreadCrumb from "../common/BreadCrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const CourseDetail = ({
  loadActiveCourse,
  activeCourse,
  userToken,
  ...props
}) => {
  const { t } = useTranslation(["translation", "course"]);
  const courseCode = props.match.params.courseCode;
  const [triedToLoad, setTriedToLoad] = useState(false);

  useEffect(() => {
    if (
      (activeCourse != undefined &&
        Object.keys(activeCourse).length === 0 &&
        !triedToLoad) ||
      activeCourse.code != courseCode
    ) {
      loadActiveCourse(courseCode, userToken).catch((error) => {
        toast.error(t("failure") + error);
        setTriedToLoad(true);
      });
    }
  }, [activeCourse, triedToLoad]);

  if (Object.keys(activeCourse).length === 0) {
    return <Spinner text={t("loading") + " " + t("course")} />;
  }

  return (
    <div
      className="container-fluid shadow p-5 bg-light rounded"
      style={{ maxWidth: "850px" }}
    >
      <Helmet>
        <title>{activeCourse.title} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <BreadCrumb
        crumbsArray={[
          { text: t("courses"), href: "/courses" },
          { text: activeCourse.title },
        ]}
      />
      <h1 className="mt-3 display-3">{activeCourse.title}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: t("course:how-to", {
            title: activeCourse.title,
            author: activeCourse.author.name,
            time: activeCourse.monthsToComplete,
          }),
        }}
      />
      <h2>{cf(t("course") + " " + t("contents"))}</h2>
      <div className="border border-success rounded p-2 mb-5">
        {activeCourse.lessons.map(({ lessonId, numberInCourse, title }) => (
          <p key={lessonId}>
            {cf(t("lesson"))} {numberInCourse}: {title}
          </p>
        ))}
      </div>
      <Link
        to={"/course/" + activeCourse.code + "/part/" + activeCourse.nextPartId}
        className="btn btn-primary"
      >
        {t("continue")}! <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    </div>
  );
};

CourseDetail.propTypes = {
  match: PropTypes.object.isRequired,
  activeCourse: PropTypes.object.isRequired,
  loadActiveCourse: PropTypes.func.isRequired,
  userToken: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    activeCourse: state.activeCourse,
    userToken: state.userProfile.token,
  };
}

const mapDispatchToProps = {
  loadActiveCourse,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
