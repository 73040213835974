import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import ValidatedInput from "../../components/common/ValidatedInput";
import ModalPopup from "../../components/common/ModalPopup";
import { validateInput } from "../../common/util";
import { INPUT_TYPES } from "../../common/constants";
import Spinner from "../../components/common/Spinner";
import {
  loadResendUserVerificationEmail,
  saveUserProfile,
} from "../../redux/actions/userActions";
import {
  loadCountries,
  loadRegions,
} from "../../redux/actions/locationActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import DropDown from "../common/DropDown";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import UserProfileBlock from "./UserProfileBlock";
import { Helmet } from "react-helmet";

const UserProfile = ({
  userProfileForm,
  loadCountries,
  loadRegions,
  token,
  saveForm,
  dbUser,
  countries,
  regions,
  loadResendUserVerificationEmail,
}) => {
  const { user, loading, logout } = useAuth0();
  const { t } = useTranslation(["translation", "policies"]);
  const [languages] = useState([
    { id: "en", name: "language-en" },
    { id: "es", name: "language-es" },
  ]);
  const [genders] = useState([
    { id: 1, name: "user-male" },
    { id: 2, name: "user-female" },
  ]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedPreferredLanguage, setSelectedPreferredLanguage] =
    useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validCountryId, setValidCountryId] = useState(false);
  const [validRegionId, setValidRegionId] = useState(false);
  const [validDateOfBirth, setValidDateOfBirth] = useState(true);
  const [validPreferredLanguage, setValidPreferredLanguage] = useState(true);
  const [validPrivacy, setValidPrivacy] = useState(true);
  const [validAgeLimit, setValidAgeLimit] = useState(true);
  const [validGender, setValidGender] = useState(true);
  const [sending, setSending] = useState(false);
  const [userUsesIdProvider, setUserUsesIdProvider] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const [isAgeLimitAccepted, setIsAgeLimitAccepted] = useState(false);
  const [userForm, setUserForm] = useState({});

  useEffect(() => {
    if (userForm.countryId && regions.length == 0) {
      loadRegions(userForm.countryId);
    }
  }, [userForm.countryId]);

  useEffect(() => {
    if (dbUser.userId) {
      setIsPolicyAccepted(true);
      setIsAgeLimitAccepted(true);
    }
  }, [dbUser.userId]);

  useEffect(() => {
    if (userForm.countryId && countries.length > 0) {
      setSelectedCountry(
        countries.filter((c) => c.alpha2Code == userForm.countryId)[0].name
      );
      setValidCountryId(true);
    }
  }, [userForm.countryId, countries]);

  useEffect(() => {
    if (userForm.preferredLanguage) {
      let filteredLanguage = languages.filter(
        (lang) => lang.id == userForm.preferredLanguage.toLowerCase()
      )[0];
      setSelectedPreferredLanguage({
        name: filteredLanguage.name,
        id: filteredLanguage.id,
      });
    }
  }, [userForm.preferredLanguage]);

  useEffect(() => {
    if (userForm.gender) {
      let filteredGender = genders.filter(
        (gen) => gen.id == userForm.gender
      )[0];
      setSelectedGender({
        name: filteredGender.name,
        id: filteredGender.id,
      });
    }
  }, [userForm.gender]);

  useEffect(() => {
    if (userForm.regionId && regions.length > 0) {
      let filteredRegion = regions.filter(
        (region) => region.id == userForm.regionId
      )[0];
      if (filteredRegion) {
        setSelectedRegion({
          name: filteredRegion.name,
          id: filteredRegion.id,
        });

        setValidRegionId(true);
      } else {
        setSelectedRegion({
          name: t("select"),
          id: null,
        });
        setValidRegionId(false);
      }
    }
  }, [userForm.regionId, regions]);

  useEffect(() => {
    setUserForm(dbUser);
  }, [dbUser]);

  useEffect(() => {
    if (user.sub.substring(0, 5) !== "auth0") {
      setUserUsesIdProvider(true);
    }
    if (!dbUser.userId && userProfileForm.triedToLoad) {
      setForm("externalId", user.sub);
    }
  }, [user, userProfileForm.triedToLoad, dbUser, userForm.email]);

  useEffect(() => {
    if (!dbUser.userId && userProfileForm.triedToLoad && !userForm.email) {
      setForm("email", user.email);
    }
  }, [userForm.externalId]);

  useEffect(() => {
    if (countries.length == 0) {
      loadCountries();
    }
  }, [countries]);

  const onCountryClick = ({ target }) => {
    setSelectedCountry(target.innerText);
    setSelectedRegion({
      name: `${t("select")} ${t("region")}`,
      id: null,
    });
    setForm("countryId", target.value);
    setValidCountryId(true);
    loadRegions(target.value);
    setValidRegionId(false);
  };
  const onRegionClick = ({ target }) => {
    setForm("regionId", target.value);
    setSelectedRegion({ name: target.innerText, id: target.value });
    setValidRegionId(true);
  };

  const onPolicyChecked = ({ target }) => {
    setIsPolicyAccepted(target.checked);
  };
  const onAgeLimitChecked = ({ target }) => {
    setIsAgeLimitAccepted(target.checked);
  };
  const onPreferredLanguageClick = ({ target }) => {
    setForm("preferredLanguage", target.value);
    setSelectedPreferredLanguage({ name: target.innerText, id: target.value });
    setValidPreferredLanguage(true);
  };
  const onGenderClick = ({ target }) => {
    setForm("gender", target.value);
    setSelectedGender({ name: target.innerText, id: target.value });
    setValidGender(true);
  };
  const onSaveClick = () => {
    if (validateForm()) {
      setSending(true);
      saveForm(userForm)
        .then(() => {
          toast.success(t("response-saved") + ".");
          setSending(false);
          setRedirect("/courses");
        })
        .catch((error) => {
          toast.error(error.message);
          setSending(false);
        });
    }
  };
  const onCancelClick = () => {
    if (dbUser.userId) {
      setRedirect("/courses");
    } else {
      logout();
    }
  };
  const setForm = (prop, value) => {
    let form = Object.assign({}, userForm);
    form[prop] = value;
    setUserForm(form);
  };
  const resendVerification = () => {
    loadResendUserVerificationEmail(token, user.sub)
      .then(() => {
        toast.success(t("user-verification-email-sent"));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const validateForm = () => {
    let isValid = true;

    if (!validEmail || !validateInput(userForm.email, INPUT_TYPES.EMAIL)) {
      setValidEmail(false);
      isValid = false;
    }
    if (
      !validDateOfBirth ||
      !validateInput(userForm.dateOfBirth, INPUT_TYPES.YEAR)
    ) {
      setValidDateOfBirth(false);
      isValid = false;
    }
    if (
      !validFirstName ||
      !validateInput(userForm.firstName, INPUT_TYPES.NAME)
    ) {
      setValidFirstName(false);
      isValid = false;
    }
    if (!validLastName || !validateInput(userForm.lastName, INPUT_TYPES.NAME)) {
      setValidLastName(false);
      isValid = false;
    }
    if (!validRegionId || !userForm.regionId) {
      setValidRegionId(false);
      isValid = false;
    }
    if (!validPreferredLanguage || !userForm.preferredLanguage) {
      setValidPreferredLanguage(false);
      isValid = false;
    }
    if (!validGender || !userForm.gender) {
      setValidGender(false);
      isValid = false;
    }
    if (!isPolicyAccepted) {
      setValidPrivacy(false);
      isValid = false;
    }
    if (!isAgeLimitAccepted) {
      setValidAgeLimit(false);
      isValid = false;
    }
    return isValid;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (loading || sending)
    return <Spinner text={t("loading") + " " + t("user-information")} />;

  if (!dbUser.userId && userProfileForm.emailAlreadyActive) {
    return <UserProfileBlock situation="emailAlreadyTaken" />;
  }
  return (
    <Card style={{ marginBottom: "40px", maxWidth: "850px", margin: "0 auto" }}>
      <Helmet>
        <title>{t("user-profile")} - MZBI</title>
        <meta name="description" content="" />
      </Helmet>
      <>
        {dbUser.userId && (
          <CardHeader>
            <h1 className="float-left">{t("user-profile-complete")}</h1>
            <div className="float-right">
              <Button color="danger" tag="a" onClick={onCancelClick}>
                {t("cancel")}
              </Button>
            </div>
          </CardHeader>
        )}
        {!user.email_verified && (
          <>
            <CardHeader>
              <h1>{t("user-verify-email-header")}</h1>
              <p>{t("user-verify-email-text")}</p>
            </CardHeader>
            <CardBody>
              <p>
                <Button
                  onClick={() => {
                    //window.location.reload(false);
                    window.location.reload();
                  }}
                  color="primary"
                >
                  {t("user-verified-button")}
                </Button>
                <Button onClick={resendVerification}>
                  {t("user-resend-verificaton")}
                </Button>
              </p>
            </CardBody>
          </>
        )}
        {user.email_verified && (
          <>
            {!dbUser.userId && (
              <CardHeader>
                <h1>{t("user-more-info")}</h1>
                <p>{t("user-fill-form")}</p>
              </CardHeader>
            )}
            <CardBody>
              <div className="form-group">
                <Row form className="mb-3">
                  <Col md="6">
                    <label className="full-width">
                      {t("first-name")}
                      <ValidatedInput
                        type="text"
                        className="form-control col"
                        errorMessage={t("input-error-message")}
                        enabled={true}
                        inputType={INPUT_TYPES.NAME}
                        isValid={validFirstName}
                        onBlur={(_, valid) => setValidFirstName(valid)}
                        onChange={(value) => setForm("firstName", value)}
                        placeholder={t("first-name")}
                        value={userForm.firstName}
                      />
                    </label>
                  </Col>
                  <Col md="6">
                    <label className="full-width">
                      {t("last-name")}
                      <ValidatedInput
                        type="text"
                        className="form-control col"
                        errorMessage={t("input-error-message")}
                        enabled={true}
                        inputType={INPUT_TYPES.NAME}
                        isValid={validLastName}
                        onBlur={(_, valid) => setValidLastName(valid)}
                        onChange={(value) => setForm("lastName", value)}
                        placeholder={t("last-name")}
                        value={userForm.lastName}
                      />
                    </label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <label className="full-width">
                      {t("email")}
                      <ValidatedInput
                        type="email"
                        className="form-control col"
                        errorMessage={t("input-error-message")}
                        enabled={!userUsesIdProvider}
                        inputType={INPUT_TYPES.EMAIL}
                        isValid={validEmail}
                        onChange={(value) => setForm("email", value)}
                        onBlur={(_, valid) => setValidEmail(valid)}
                        value={userForm.email}
                        placeholder={t("email")}
                      />
                      {userUsesIdProvider && t("user-ext-id-notice")}
                    </label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <label>
                      {t("year-of-birth")}
                      <ValidatedInput
                        type="year"
                        className="form-control col"
                        errorMessage={t("input-error-message")}
                        enabled={true}
                        inputType={INPUT_TYPES.YEAR}
                        isValid={validDateOfBirth}
                        onBlur={(_, valid) => setValidDateOfBirth(valid)}
                        onChange={(value) => setForm("dateOfBirth", value)}
                        placeholder={t("year-of-birth")}
                        value={userForm.dateOfBirth}
                      />
                    </label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">
                    <label>{t("male-or-female")}</label>
                    <DropDown
                      errorMessage={t("input-error-message")}
                      items={genders}
                      label={selectedGender ? selectedGender.name : "select"}
                      text="name"
                      value="id"
                      enabled={true}
                      onClick={onGenderClick}
                      isValid={validGender}
                      translateItems={true}
                    />
                  </Col>
                  <Col md="6">
                    <label>{t("preferred-language")}</label>
                    <DropDown
                      errorMessage={t("input-error-message")}
                      items={languages}
                      label={
                        selectedPreferredLanguage
                          ? selectedPreferredLanguage.name
                          : "select"
                      }
                      text="name"
                      value="id"
                      enabled={true}
                      onClick={onPreferredLanguageClick}
                      isValid={validPreferredLanguage}
                      translateItems={true}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="6">
                    <label className="full-width">{t("country")}</label>
                    <DropDown
                      className="full-width"
                      errorMessage={t("input-error-message")}
                      enabled={true}
                      items={countries}
                      label={selectedCountry ? selectedCountry : t("select")}
                      onClick={onCountryClick}
                      text="name"
                      value="alpha2Code"
                      isValid={validCountryId}
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "400px",
                              },
                            };
                          },
                        },
                      }}
                    />
                  </Col>
                  <Col md="6">
                    <label className="full-width">{`${t("state")} / ${t(
                      "region"
                    )}`}</label>
                    <DropDown
                      className="full-width"
                      errorMessage={t("input-error-message")}
                      items={regions}
                      label={selectedRegion ? selectedRegion.name : t("select")}
                      text="name"
                      value="id"
                      enabled={validCountryId}
                      onClick={onRegionClick}
                      isValid={validRegionId}
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: "400px",
                              },
                            };
                          },
                        },
                      }}
                    />
                  </Col>
                </Row>

                <Row className="mb-3 mt-5">
                  <Col md="8">
                    <input
                      type="checkbox"
                      onChange={onPolicyChecked}
                      checked={isPolicyAccepted}
                    />{" "}
                    {t("user-do-you-agree-policy")}
                    <div
                      className="invalid-feedback"
                      style={{ display: validPrivacy ? "none" : "block" }}
                    >
                      {t("policies:user-must-accept-privacy-policy")}
                    </div>
                  </Col>
                  <Col md="4">
                    <ModalPopup
                      buttonLabel={
                        t("policies:privacy-policy") +
                        " & " +
                        t("policies:terms-use")
                      }
                      color="secondary"
                      headerText={
                        t("policies:privacy-policy") +
                        " & " +
                        t("policies:terms-use")
                      }
                      bodyText={
                        t("policies:terms-use-text") +
                        " " +
                        t("policies:privacy-policy-text")
                      }
                      closeText={t("close")}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <input
                      type="checkbox"
                      onChange={onAgeLimitChecked}
                      checked={isAgeLimitAccepted}
                    />{" "}
                    {t("user-are-you-over-age-limit")}
                    <div
                      className="invalid-feedback"
                      style={{ display: validAgeLimit ? "none" : "block" }}
                    >
                      {t("policies:user-must-accept-age-limit")}
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button
                    color="success"
                    onMouseDown={() => {
                      validateForm();
                    }}
                    onClick={() => {
                      onSaveClick();
                    }}
                    className="full-width"
                  >
                    {t("save")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="danger"
                    tag="a"
                    className="full-width"
                    onClick={onCancelClick}
                  >
                    {t("cancel")}
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </>
        )}
      </>
    </Card>
  );
};

UserProfile.propTypes = {
  saveForm: PropTypes.func.isRequired,
  loadCountries: PropTypes.func.isRequired,
  loadRegions: PropTypes.func.isRequired,
  userProfileForm: PropTypes.object.isRequired,
  dbUser: PropTypes.object,
  token: PropTypes.string.isRequired,
  loadResendUserVerificationEmail: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    loadResendUserVerificationEmail: (token, externalId) =>
      dispatch(loadResendUserVerificationEmail(token, externalId)),
    saveForm: (userForm) => dispatch(saveUserProfile(userForm)),
    loadCountries: () => dispatch(loadCountries()),
    loadRegions: (countryId) => dispatch(loadRegions(countryId)),
  };
}

const mapStateToProps = (state) => ({
  userProfileForm: state.userProfile,
  dbUser: state.userProfile.dbUser,
  token: state.userProfile.token,
  countries: state.locations.countries,
  regions: state.locations.regions,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
