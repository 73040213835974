import React from "react";
import { PropTypes } from "prop-types";
import { Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter as cf } from "../../common/util";
import { Link } from "react-router-dom";

const CourseContentsList = ({ course, activeRowNum, toggle }) => {
  const { t } = useTranslation();

  return (
    <ul className="list-group mt-3">
      {course.lessons.map(({ numberInCourse, title, parts }, i) => {
        const colorClass =
          activeRowNum == numberInCourse ? "list-group-item-success" : "";

        return (
          <li key={i} className={"list-group-item" + " " + colorClass}>
            {cf(t("lesson")) + " " + numberInCourse}. {title}
            <ul>
              {parts &&
                parts.map((part, i) => {
                  let complete =
                    part.countAnsweredQuestionsInPart ===
                    part.countQuestionsInPart;
                  let badgeColor = complete ? "success" : "warning";
                  return (
                    <li key={i} className="d-flex align-items-center">
                      <Badge pill color={badgeColor} className="mr-3">
                        {part.countQuestionsInPart > 0 &&
                          complete &&
                          t("complete")}
                        {part.countQuestionsInPart < 1 &&
                          t("no") + " " + t("questions")}
                        {part.countQuestionsInPart > 0 &&
                          !complete &&
                          part.countAnsweredQuestionsInPart +
                            " " +
                            t("of") +
                            " " +
                            part.countQuestionsInPart}
                      </Badge>
                      <Link
                        to={"/course/" + course.code + "/part/" + part.partId}
                        onClick={toggle}
                      >
                        {part.title}
                      </Link>{" "}
                      {course.nextPartId === part.partId && (
                        <Badge pill color="info" className="ml-3">
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} />{" "}
                          {t("continue-where-left-off")}
                        </Badge>
                      )}
                    </li>
                  );
                })}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};

CourseContentsList.propTypes = {
  course: PropTypes.object.isRequired,
  activeRowNum: PropTypes.number,
  toggle: PropTypes.func,
};

export default CourseContentsList;
