import React from "react";
import {
  Badge,
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  ButtonGroup,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MentorCourseCardListItemDetail from "./MentorCourseCardListItemDetail";

const MentorCourseCardList = ({ userCourses, onCourseSelectClick }) => {
  const { t } = useTranslation(["translation", "mentor"]);
  const count = userCourses ? userCourses.length : 0;

  return (
    <ListGroup flush>
      {count > 0 &&
        userCourses.map((userCourse) => {
          let gradingStatusColor =
            userCourse.gradingStatus == "complete" ? "success" : "warning";
          gradingStatusColor =
            userCourse.gradingStatus == "you-started-grading"
              ? "info"
              : gradingStatusColor;
          let selectButtonText =
            userCourse.gradingStatus == "ready-to-start" ? "start" : "select";
          selectButtonText =
            userCourse.gradingStatus == "you-started-grading"
              ? "continue"
              : selectButtonText;

          return (
            <ListGroupItem key={userCourse.usersCourseId} className="pb-4">
              <Badge color={gradingStatusColor} className="float-right">
                {t("mentor:mentor-grading-status-" + userCourse.gradingStatus)}
              </Badge>
              <ListGroupItemHeading>
                {userCourse.studentName}
              </ListGroupItemHeading>
              <ButtonGroup className="float-right">
                <MentorCourseCardListItemDetail userCourse={userCourse} />
                <Button
                  onClick={() =>
                    onCourseSelectClick(
                      userCourse.groupId,
                      userCourse.usersCourseId
                    )
                  }
                  size="sm"
                >
                  {t(selectButtonText)}
                </Button>
              </ButtonGroup>
              <ListGroupItemText>
                {userCourse.courseCode} {userCourse.courseTitle}
              </ListGroupItemText>
            </ListGroupItem>
          );
        })}
      {count == 0 && (
        <ListGroupItem className="pb-4">
          {t("mentor:mentor-no-results")}
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

MentorCourseCardList.propTypes = {
  userCourses: PropTypes.array.isRequired,
  onCourseSelectClick: PropTypes.func,
};

export default MentorCourseCardList;
