import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userProfileReducer(
  state = initialState.userProfile,
  action
) {
  switch (action.type) {
    case types.USER_LOAD_PROFILE_SUCCESS:
      return { ...state, triedToLoad: true, dbUser: action.response };
    case types.USER_LOAD_PROFILE_FAILURE:
      return {
        ...state,
        triedToLoad: true,
        emailAlreadyActive: action.error.message == "Email is already taken.",
      };
    case types.USER_LOAD_TOKEN_SUCCESS:
      return { ...state, token: action.token };
    default:
      return state;
  }
}
