import * as types from "./actionTypes";
import * as messageApi from "../../api/messageApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadMessagesSuccess(response) {
  return { type: types.MESSAGES_LOAD_SUCCESS, response };
}
export function updateMessageSuccess(response) {
  return { type: types.MESSAGE_UPDATE_SUCCESS, response };
}

export function updateMessage(message) {
  //currently only used for adding dateRead. If used for anything else, then pass the appropriate info.
  message = { messagesId: message.id };
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return messageApi
      .markUserMessageRead(token, message)
      .then((response) => {
        dispatch(updateMessageSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadMessages() {
  return function (dispatch, getState) {
    const { userProfile } = getState();
    const { token } = userProfile;
    dispatch(beginApiCall());
    return messageApi
      .getUserMessages(token)
      .then((response) => {
        dispatch(loadMessagesSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
