import React, { useState } from "react";
import { Col, Card, CardHeader, CardFooter, CardText, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DropDown from "../common/DropDown";
import MentorCourseCardList from "./MentorCourseCardList";

const MentorCourseCard = ({
  titleI18nKey,
  textI18nKey,
  bgColor,
  userCourses,
  badge,
  onCourseClick,
  coursesForFilter,
  onFilterPotentialCoursesClick,
}) => {
  const { t } = useTranslation();
  const count = userCourses ? userCourses.length : 0;
  const [selectedCourse, setSelectedCourse] = useState(null);
  const onCourseSelectClick = (groupId, ucId) => {
    setSelectedCourse(null);
    onCourseClick(groupId, ucId);
  };
  const onCourseFilterClick = ({ target }) => {
    setSelectedCourse({ title: target.innerText, courseId: target.value });
    onFilterPotentialCoursesClick(target.value);
  };
  return (
    <>
      <Col sm="6" md="4" className="mb-3 mt-3">
        <Card>
          <CardHeader style={{ backgroundColor: bgColor || "gray" }}>
            <h3 className="float-left">{t(titleI18nKey)} </h3>
            {badge && (
              <Badge color="danger" pill className="float-right">
                {count} {t("active")}
              </Badge>
            )}
            {coursesForFilter && (
              <DropDown
                errorMessage={""}
                items={coursesForFilter}
                label={
                  selectedCourse
                    ? selectedCourse.title
                    : `${t("all")} ${t("courses")}`
                }
                text="title"
                value="courseId"
                enabled={true}
                onClick={onCourseFilterClick}
                isValid={true}
              />
            )}
          </CardHeader>

          <MentorCourseCardList
            userCourses={userCourses}
            onCourseSelectClick={onCourseSelectClick}
          />

          <CardFooter style={{ backgroundColor: "rgb(205 231 220)" }}>
            <CardText>{t(textI18nKey)}</CardText>
          </CardFooter>
        </Card>
      </Col>
    </>
  );
};

MentorCourseCard.propTypes = {
  titleI18nKey: PropTypes.string,
  textI18nKey: PropTypes.string,
  bgColor: PropTypes.string,
  userCourses: PropTypes.array,
  badge: PropTypes.bool,
  onCourseClick: PropTypes.func,
  coursesForFilter: PropTypes.array,
  onFilterPotentialCoursesClick: PropTypes.func,
};
export default MentorCourseCard;
