import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/users/";

export function getUserStudentCourses(userId) {
  return fetch(baseUrl + "/" + userId + "/courses/")
    .then(handleResponse)
    .catch(handleError);
}
export function getUserProfile(token, email) {
  return fetch(baseUrl + "?email=" + encodeURI(email), {
    headers: new Headers({
      Authorization: "Bearer " + token,
    }),
  })
    .then(handleResponse)
    .catch(handleError);
}
export function saveUserProfile(token, data) {
  return fetch(process.env.API_URL + "/users", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch((error) => {
      handleError(error);
    });
}

export function enrollInCoursePost(courseId, userId, token) {
  return fetch(
    process.env.API_URL + "/courses/" + courseId + "/student/" + userId,
    {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export function saveStudentAnswer(answer) {
  const _answer = {
    Response: answer.response,
  };
  return fetch(
    process.env.API_URL +
      "/courses/" +
      answer.courseCode +
      "/student/" +
      answer.userId +
      "/question/" +
      answer.questionId,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + answer.token,
      },
      body: JSON.stringify(_answer),
    }
  )
    .then(handleResponse)
    .catch(handleError);
}
export function resendVerificationEmail(token, externalId) {
  const params = {
    externalId,
  };
  return fetch(process.env.API_URL + "/users/verify", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(params),
  })
    .then(handleResponse)
    .catch(handleError);
}
