import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function courseReducer(state = initialState.courses, action) {
  let courseToUpdate;
  let otherCourses;

  switch (action.type) {
    case types.CREATE_COURSE_SUCCESS:
      return [...state, { ...action.course }];
    case types.UPDATE_COURSE_SUCCESS:
      return state.map((course) =>
        course.id === action.course.id ? action.course : course
      );
    case types.USER_ENROLL_IN_COURSE_SUCCESS:
      courseToUpdate = state.filter(
        (r) => r.courseId === action.userCourse.courseId
      )[0];
      otherCourses = state.filter(
        (r) => r.courseId !== action.userCourse.courseId
      );
      courseToUpdate = {
        ...courseToUpdate,
        dateSignedUp: action.userCourse.dateSignedUp,
      };
      return [...otherCourses, { ...courseToUpdate }];
    case types.LOAD_COURSES_SUCCESS:
      return action.courses;
    case types.DELETE_COURSE_OPTIMISTIC:
      return state.filter((course) => course.id !== action.course.id);
    case types.UNLOAD_COURSES:
      return [];
    default:
      return state;
  }
}
