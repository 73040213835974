import { combineReducers } from "redux";
import activeCourse from "./activeCourseReducer";
import courses from "./courseReducer";
import courseTopics from "./courseTopicsReducer";
import courseLevels from "./courseLevelsReducer";
import messages from "./messageReducer";
import siteNotice from "./siteNoticeReducer";
import initialCourse from "./initialCourseReducer";
import mostRecentCourse from "./mostRecentCourseReducer";
import activePart from "./lessonReducer";
import activeQuestions from "./questionReducer";
import appPreferences from "./appPreferencesReducer";
import apiCallsInProgress from "./apiStatusReducer";
import userProfile from "./userProfileReducer";
import mentorCourses from "./mentorCoursesReducer";
import userStudentCourses from "./userStudentCoursesReducer";
import locations from "./locationsReducer";

const appReducer = combineReducers({
  courses,
  initialCourse,
  mostRecentCourse,
  courseTopics,
  courseLevels,
  activeCourse,
  activePart,
  activeQuestions,
  appPreferences,
  apiCallsInProgress,
  locations,
  messages,
  siteNotice,
  userProfile,
  mentorCourses,
  userStudentCourses,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
