import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/courses/";

export function getCourses(token = null) {
  const url = token !== null ? baseUrl + "student" : baseUrl;
  const headers = token !== null ? { Authorization: "Bearer " + token } : {};
  return fetch(url, {
    headers: headers,
  })
    .then(handleResponse)
    .catch(handleError);
}
export function getCourse(code, token = null) {
  const url = baseUrl + code;
  const headers = token !== null ? { Authorization: "Bearer " + token } : {};
  return fetch(url, {
    headers: headers,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getMostRecentCourse(token) {
  const url = baseUrl + "getrecent";
  const headers = { Authorization: "Bearer " + token };
  return fetch(url, {
    headers: headers,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCourseCertificate(code, token) {
  const url = baseUrl + code + "/student/certificate";
  const headers = { Authorization: "Bearer " + token };
  return fetch(url, {
    headers: headers,
  }).then((r) => {
    if (r.status === 200) {
      return r.blob();
    }
  });
}
export function getInitialCourses(lang = "en") {
  return fetch(baseUrl + "getinitial?language=" + lang)
    .then(handleResponse)
    .catch(handleError);
}
export function getCourseTopics(lang = "en") {
  return fetch(baseUrl + "gettopics?language=" + lang)
    .then(handleResponse)
    .catch(handleError);
}
export function getCourseLevels() {
  return fetch(baseUrl + "getlevels")
    .then(handleResponse)
    .catch(handleError);
}
