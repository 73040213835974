import * as types from "./actionTypes";
import * as lessonApi from "../../api/lessonApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadActivePartSuccess(part) {
  return { type: types.LOAD_ACTIVE_PART_SUCCESS, part };
}

export function loadActivePart(courseCode, partId, token) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return lessonApi
      .getActivePart(courseCode, partId, token)
      .then((part) => {
        dispatch(loadActivePartSuccess(part));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export const clearActivePart = () => (dispatch) =>
  dispatch({
    type: types.CLEAR_ACTIVE_PART,
  });
