import { handleResponse, handleError } from "./apiUtils";
const baseUrl = process.env.API_URL + "/locations/";

export const getCountries = () => {
  return fetch(baseUrl + "countries")
    .then(handleResponse)
    .catch(handleError);
};

export const getRegions = (countryCode) => {
  return fetch(baseUrl + "regionsByCountry?countryId=" + countryCode)
    .then(handleResponse)
    .catch(handleError);
};
