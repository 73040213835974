import * as types from "./actionTypes";
import * as siteNoticeApi from "../../api/siteNoticeApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadSiteNoticeSuccess(response) {
  return { type: types.LOAD_SITE_Notice, response };
}

export function loadSiteNotice() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return siteNoticeApi
      .getSiteNotice()
      .then((response) => {
        dispatch(loadSiteNoticeSuccess(response));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
