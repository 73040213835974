import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const LoginButton = ({ label, redirectTo }) => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  const text = label ? label : t("log-in");
  const options = redirectTo
    ? {
        appState: { returnTo: redirectTo },
      }
    : null;
  return (
    <button
      className="btn btn-primary"
      onClick={() => loginWithRedirect(options)}
    >
      <FontAwesomeIcon icon={faUser} /> {text}
    </button>
  );
};

LoginButton.propTypes = {
  label: PropTypes.string,
  redirectTo: PropTypes.string,
};

export default LoginButton;
