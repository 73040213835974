import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
  const { t } = useTranslation(["policies"]);

  return (
    <div
      className="container-fluid shadow p-1 p-md-5 bg-light rounded"
      style={{ maxWidth: "850px" }}
    >
      <Helmet>
        <title>
          {t("policies:terms-use") + " & " + t("policies:privacy-policy")} -
          MZBI
        </title>
        <meta name="description" content="" />
      </Helmet>
      <h1 className="display-4">
        {t("policies:terms-use") + " & " + t("policies:privacy-policy")}
      </h1>

      <h2>{t("policies:terms-use-header")}</h2>

      <div dangerouslySetInnerHTML={{ __html: t("policies:terms-use-text") }} />
      <h2>{t("policies:privacy-policy-header")}</h2>

      <div
        dangerouslySetInnerHTML={{ __html: t("policies:privacy-policy-text") }}
      />
    </div>
  );
};

export default TermsOfUse;
